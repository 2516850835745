// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
// import SignInPage from './components/SignInPage';
// import WelcomePage from './components/WelcomePage';
// import ImageGenerationPage from './components/ImageGenerationPage';
// import ManageMembershipPage from './components/ManageMembership';
// import GuidesPage from './components/GuidesPage';
// import ProtectedRoute from './components/ProtectedRoute';
// import './global.css';
// function App() {
  
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   useEffect(() => {
//     const checkAuth = () => {
//       const auth = localStorage.getItem('authenticated') === 'true';
//       setIsAuthenticated(auth);
     
//     };

//     checkAuth();
//     window.addEventListener('storage', checkAuth);
//     window.addEventListener('authChange', checkAuth);

//     return () => {
//       window.removeEventListener('storage', checkAuth);
//       window.removeEventListener('authChange', checkAuth);
//     };
//   }, []);

//   const NavigationBar = () => (
//     <nav className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-4 sticky top-0 z-50 border-b border-gray-200 border-opacity-20">
//       <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
//         <Link to="/welcome" className="text-2xl font-bold text-white flex items-center transition-transform transform hover:scale-105">
//           <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-2 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
//             <path d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" />
//           </svg>
//           <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-300">
//             Whiteberry
//           </span>
//         </Link>
//         <button
//           onClick={() => setIsMenuOpen(!isMenuOpen)}
//           className="lg:hidden text-white focus:outline-none"
//         >
//           <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
//           </svg>
//         </button>
//         <ul className={`${isMenuOpen ? 'block' : 'hidden'} lg:flex lg:space-x-8 w-full lg:w-auto mt-4 lg:mt-0`}>
//           {[
//             { to: "/welcome", label: "Home" },
//             { to: "/create", label: "Create Images" },
//             { to: "/guides", label: "Guides" },
//             { to: "/manage-membership", label: "Manage Membership" },
//           ].map((link) => (
//             <li key={link.to} className="mt-2 lg:mt-0">
//               <Link
//                 to={link.to}
//                 className="text-gray-300 hover:text-white font-medium transition-colors duration-200 ease-in-out relative group block py-2 lg:inline-block lg:py-0"
//                 onClick={() => setIsMenuOpen(false)}
//               >
//                 {link.label}
//                 <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-blue-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200 ease-in-out"></span>
//               </Link>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </nav>
//   );

//   return (
//     <Router>
//       <div className="App min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
//         {isAuthenticated && <NavigationBar />}

//         <main className="max-w-7xl mx-auto p-4 sm:p-6 md:p-8">
//           <Routes>
//             <Route path="/signin" element={<SignInPage setIsAuthenticated={setIsAuthenticated} />} />
//             <Route 
//               path="/welcome" 
//               element={
//                 <ProtectedRoute isAuthenticated={isAuthenticated}>
//                   <WelcomePage />
//                 </ProtectedRoute>
//               } 
//             />
//             <Route 
//               path="/create" 
//               element={
//                 <ProtectedRoute isAuthenticated={isAuthenticated}>
//                   <ImageGenerationPage />
//                 </ProtectedRoute>
//               } 
//             />
//             <Route 
//               path="/guides" 
//               element={
//                 <ProtectedRoute isAuthenticated={isAuthenticated}>
//                   <GuidesPage />
//                 </ProtectedRoute>
//               } 
//             />
//             <Route 
//               path="/manage-membership" 
//               element={
//                 <ProtectedRoute isAuthenticated={isAuthenticated}>
//                   <ManageMembershipPage />
//                 </ProtectedRoute>
//               } 
//             />
//             <Route path="/" element={<Navigate to="/signin" replace />} />
//             <Route path="*" element={<Navigate to="/signin" replace />} />
//           </Routes>
//         </main>

//         <footer className="bg-white bg-opacity-5 text-gray-400 text-center py-4 mt-16">
//           <p>&copy; 2024 Whiteberry. All rights reserved.</p>
//         </footer>
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import SignInPage from './components/SignInPage';
import WelcomePage from './components/WelcomePage';
import ImageGenerationPage from './components/ImageGenerationPage';
import ManageMembershipPage from './components/ManageMembership';
import GuidesPage from './components/GuidesPage';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './components/LandingPage';
import './global.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const auth = localStorage.getItem('authenticated') === 'true';
      setIsAuthenticated(auth);
    };

    checkAuth();
    window.addEventListener('storage', checkAuth);
    window.addEventListener('authChange', checkAuth);

    return () => {
      window.removeEventListener('storage', checkAuth);
      window.removeEventListener('authChange', checkAuth);
    };
  }, []);

  const NavigationBar = () => (
    <nav className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-4 sticky top-0 z-50 border-b border-gray-200 border-opacity-20">
      <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
        <Link to="/" className="text-2xl font-bold text-white flex items-center transition-transform transform hover:scale-105">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-2 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" />
          </svg>
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-300">
            Whiteberry
          </span>
        </Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden text-white focus:outline-none"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <ul className={`${isMenuOpen ? 'block' : 'hidden'} lg:flex lg:space-x-8 w-full lg:w-auto mt-4 lg:mt-0`}>
          {[
            { to: "/", label: "Home" },
            { to: "/create", label: "Create Images" },
            { to: "/guides", label: "Guides" },
            { to: "/manage-membership", label: "Manage Membership" },
          ].map((link) => (
            <li key={link.to} className="mt-2 lg:mt-0">
              <Link
                to={link.to}
                className="text-gray-300 hover:text-white font-medium transition-colors duration-200 ease-in-out relative group block py-2 lg:inline-block lg:py-0"
                onClick={() => setIsMenuOpen(false)}
              >
                {link.label}
                <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-blue-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200 ease-in-out"></span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );

  return (
    <Router>
      <div className="App min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
        {isAuthenticated && <NavigationBar />}

        <main className="max-w-7xl mx-auto p-4 sm:p-6 md:p-8">
          <Routes>
            <Route path="/signin" element={<SignInPage setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/" element={<LandingPage />} />
            <Route 
              path="/welcome" 
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <WelcomePage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/create" 
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ImageGenerationPage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/guides" 
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <GuidesPage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/manage-membership" 
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ManageMembershipPage />
                </ProtectedRoute>
              } 
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>

        <footer className="bg-white bg-opacity-5 text-gray-400 text-center py-4 mt-16">
          <p>&copy; 2024 Whiteberry. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
