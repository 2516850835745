import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, Box, Video, ChevronDown, ChevronUp } from 'lucide-react';

const GuidesPage = () => {
  const [expandedGuide, setExpandedGuide] = useState(null);

  const toggleGuide = (index) => {
    setExpandedGuide(expandedGuide === index ? null : index);
  };

  const guides = [
    { 
      title: "Using Whiteberry to Create An Ad (For Free)", 
      icon: <Play className="w-8 h-8 text-blue-400" />,
      description: "Learn how to leverage Whiteberry's AI to create compelling advertisements for your brand.",
      steps: [
        "Step 1: Follow above video to create the detailed and relevant imagery.",
        "Step 2: Prepare your Logo.",
        "Step 3: Search Adobe Express on Google.",
        "Step 4: Once on Adobe Express, Find 'insert object' and click on that feature (Free).",
        "Step 5: Upload the Whiteberry Created Image and then after upload your Logo. Enjoy !"
      ]
    },
    { 
      title: "Using Whiteberry to Create a 3D Model (For Free)", 
      icon: <Box className="w-8 h-8 text-teal-400" />,
      description: "Discover the process of generating and refining 3D models using Whiteberry's advanced AI tools.",
      steps: [
        "Step 1: Follow above video to create the detailed and relevant imagery.",
        "Step 1.5: Ensure you enhanced your Image for better results.",
        "Step 2: Search Aiuni.ai on Google.",
        "Step 4: Make sure '3D Model' selected at the top, click on the center logo and upload your image.",
        "Step 5: Finally click the icon on the right to Generate. Enjoy !"
      ]
    },
    { 
      title: "Using Whiteberry to Create a Video/Commercial (For Free)", 
      icon: <Video className="w-8 h-8 text-purple-400" />,
      description: "Explore how to use Whiteberry to produce professional-quality videos and commercials.",
      steps: [
        "Step 1: Follow above video to create the detailed and relevant imagery.",
        "Step 2: Search Luma Dream Machine on Google.",
        "Step 3: Click on 'Try Now' and sign in with Google.",
        "Step 4: In the text bar you will see you can upload your images.",
        "Step 5: Upload 1-2 images for your desired effect and type in text to indicate what the content between should be",
        "Step 6: Get Creative. Enjoy !"
    ]
    }
  ];

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-gradient-to-br from-gray-900 to-black text-white">
      <div className="max-w-7xl mx-auto">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl sm:text-5xl font-bold text-center mb-16 sm:mb-20 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300"
        >
          Whiteberry AI Guides
        </motion.h1>

        {/* <div className="mb-16 text-center">
          <h2 className="text-3xl font-semibold mb-6 text-gray-200">Getting Started with Whiteberry</h2>
          <div className="aspect-w-16 aspect-h-9 max-w-5xl mx-auto">
            <iframe 
              src="https://www.youtube.com/embed/JwwITmmG58U" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              className="rounded-lg shadow-lg w-full h-full"
            ></iframe>
          </div>
        </div> */}
        <div className="mb-16 text-center">
  <h2 className="text-3xl font-semibold mb-6 text-gray-200">Getting Started with Whiteberry</h2>
  <div className="relative w-full max-w-4xl mx-auto" style={{ paddingBottom: '56.25%' }}> {/* Adjusted max-width and aspect ratio */}
    <iframe 
      src="https://www.youtube.com/embed/74C4PPmzbgM" 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen
      className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
    ></iframe>
  </div>
</div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {guides.map((guide, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-2xl p-6 border border-gray-700 border-opacity-50 hover:bg-opacity-70 transition duration-300"
            >
              <div className="flex items-start">
                <div className="mr-4">{guide.icon}</div>
                <div className="flex-grow">
                  <h3 className="text-xl font-semibold mb-2 text-gray-200">{guide.title}</h3>
                  <p className="text-gray-400 text-sm mb-4">{guide.description}</p>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => toggleGuide(index)}
                    className="px-4 py-2 bg-gradient-to-r from-blue-500 to-teal-400 text-white rounded-full text-sm font-medium hover:from-blue-600 hover:to-teal-500 transition duration-300 flex items-center"
                  >
                    {expandedGuide === index ? 'Hide Steps' : 'View Guide'}
                    {expandedGuide === index ? <ChevronUp className="ml-2" size={16} /> : <ChevronDown className="ml-2" size={16} />}
                  </motion.button>
                </div>
              </div>
              <AnimatePresence>
                {expandedGuide === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mt-4"
                  >
                    <ul className="list-disc list-inside text-gray-300">
                      {guide.steps.map((step, stepIndex) => (
                        <li key={stepIndex} className="mt-2">{step}</li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuidesPage;