import React, { useState, useEffect } from 'react';
import { Sparkles, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscription = async () => {
      const email = localStorage.getItem('userEmail');
      if (!email) {
        navigate('/signin');
        return;
      }

      
      const checkSubscriptionUrl = process.env.REACT_APP_SUBSCRIPTION_CHECK_API_GATEWAY;
      try {
        const checkResponse = await fetch(checkSubscriptionUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        });

        const checkData = await checkResponse.json();
        console.log('Subscription Check Response:', checkData);

        let parsedCheckData = checkData;
        if (typeof checkData.body === 'string') {
          parsedCheckData = JSON.parse(checkData.body);
        }

        setIsSubscribed(parsedCheckData.isSubscribed);

        if (parsedCheckData.isSubscribed) {
          navigate('/create');
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    };

    checkSubscription();
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
      <div className="w-full max-w-2xl p-8 space-y-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl shadow-xl border border-gray-200 border-opacity-20">
        <div className="text-center">
          <Sparkles className="text-blue-400 mx-auto mb-4" size={40} />
          <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 mb-4">Welcome to Whiteberry</h1>
          <p className="text-xl text-gray-300 mb-8">AI Creation and Enhancement Hub</p>
        </div>
        <div className="space-y-6">
          <p className="text-lg text-center text-gray-300">
            {isSubscribed 
              ? "You're all set! Start creating amazing AI-generated images now."
              : "You're currently not a member. Purchase a subscription to unlock the full potential of Whiteberry AI!"}
          </p>
          <button
            onClick={() => navigate(isSubscribed ? '/create' : '/manage-membership')}
            className="w-full px-6 py-4 text-lg font-semibold text-white bg-gradient-to-r from-blue-500 to-teal-400 rounded-full hover:from-blue-600 hover:to-teal-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center"
          >
            {isSubscribed ? 'Start Creating' : 'Get Membership'}
            <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>
      {!isSubscribed && (
        <div className="mt-12 text-center">
          <h2 className="text-2xl font-semibold mb-4 text-gray-200">Why Choose Whiteberry?</h2>
          <ul className="space-y-4 text-gray-300">
            <li>State-of-the-art AI image generation</li>
            <li>Advanced Enhancement tools</li>
            <li>Unlimited Creations with Pro Membership</li>
            <li>Secure Image Generation</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default WelcomePage;
