// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

.stars, .twinkling {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
}

.stars {
  background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
  z-index:0;
}

.twinkling{
  background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;
  z-index:1;
  animation:move-twink-back 200s linear infinite;
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}
.shadow-neon { text-shadow: 0 0 10px #ff00de, 0 0 20px #ff00de, 0 0 30px #ff00de; }`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,MAAM,uBAAuB,CAAC;IAC9B,IAAI,mCAAmC,CAAC;AAC5C;;AAEA;EACE,iBAAiB;EACjB,KAAK;EACL,MAAM;EACN,OAAO;EACP,QAAQ;EACR,UAAU;EACV,WAAW;EACX,aAAa;AACf;;AAEA;EACE,iGAAiG;EACjG,SAAS;AACX;;AAEA;EACE,4GAA4G;EAC5G,SAAS;EACT,8CAA8C;AAChD;;AAEA;EACE;IACE,uCAAuC;EACzC;EACA;IACE,4CAA4C;EAC9C;EACA;IACE,4CAA4C;EAC9C;EACA;IACE,uCAAuC;EACzC;AACF;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;AACA,eAAe,iEAAiE,EAAE","sourcesContent":["@keyframes move-twink-back {\n    from {background-position:0 0;}\n    to {background-position:-10000px 5000px;}\n}\n\n.stars, .twinkling {\n  position:absolute;\n  top:0;\n  left:0;\n  right:0;\n  bottom:0;\n  width:100%;\n  height:100%;\n  display:block;\n}\n\n.stars {\n  background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;\n  z-index:0;\n}\n\n.twinkling{\n  background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;\n  z-index:1;\n  animation:move-twink-back 200s linear infinite;\n}\n\n@keyframes blob {\n  0% {\n    transform: translate(0px, 0px) scale(1);\n  }\n  33% {\n    transform: translate(30px, -50px) scale(1.1);\n  }\n  66% {\n    transform: translate(-20px, 20px) scale(0.9);\n  }\n  100% {\n    transform: translate(0px, 0px) scale(1);\n  }\n}\n\n.animate-blob {\n  animation: blob 7s infinite;\n}\n\n.animation-delay-2000 {\n  animation-delay: 2s;\n}\n\n.animation-delay-4000 {\n  animation-delay: 4s;\n}\n.shadow-neon { text-shadow: 0 0 10px #ff00de, 0 0 20px #ff00de, 0 0 30px #ff00de; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
