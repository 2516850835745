// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// const LandingPage = () => {
//   const [beforeAfterToggle, setBeforeAfterToggle] = useState(false);

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
//       <div className="text-center mb-12">
//         <h1 className="text-5xl font-bold mb-4">
//           The #1 AI-Powered
//           <br />
//           <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
//             Image Generation And Enhancement Tool
//           </span>
//         </h1>
//         <p className="text-xl text-gray-300 mb-8">
//           Generate, enhance, and transform images with advanced AI technology.
//         </p>
//         <div className="flex justify-center space-x-4">
//           <Link
//             to="/signin"
//             className="px-8 py-3 bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 ease-in-out transform hover:scale-105"
//           >
//             Get Started
//           </Link>
//           <a
//             href="#features"
//             className="px-8 py-3 bg-white bg-opacity-10 text-white font-semibold rounded-full hover:bg-opacity-20 transition duration-300 ease-in-out"
//           >
//             Features
//           </a>
//         </div>
//       </div>

//       <div className="w-full max-w-4xl mb-16">
//         <div className="bg-white bg-opacity-10 rounded-lg p-8">
//           <h2 className="text-3xl font-semibold mb-4">Before / After Example</h2>
//           <div className="relative">
//             <img
//               src={beforeAfterToggle ? "/path/to/after-image.jpg" : "/path/to/before-image.jpg"}
//               alt={beforeAfterToggle ? "After Enhancement" : "Before Enhancement"}
//               className="w-full h-auto rounded-lg"
//             />
//             <div className="absolute inset-0 flex items-center justify-center">
//               <button
//                 onClick={() => setBeforeAfterToggle(!beforeAfterToggle)}
//                 className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
//               >
//                 {beforeAfterToggle ? "View Original" : "View Enhanced"}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div id="features" className="w-full max-w-4xl">
//         <h2 className="text-3xl font-semibold mb-8 text-center">Key Features</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           <FeatureCard
//             title="Advanced AI Generation"
//             description="Create stunning images from text descriptions using state-of-the-art AI models."
//             icon="🎨"
//           />
//           <FeatureCard
//             title="Image Enhancement"
//             description="Automatically improve image quality, remove noise, and increase resolution."
//             // icon="✨"
//           />
//           <FeatureCard
//             title="Style Transfer"
//             description="Apply artistic styles to your images with just a few clicks."
//             // icon="🖼️"
//           />
//           <FeatureCard
//             title="Batch Processing"
//             description="Enhance multiple images at once, saving you time and effort."
//             // icon="⚡"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// const FeatureCard = ({ title, description, icon }) => (
//   <div className="bg-white bg-opacity-10 rounded-lg p-6 hover:bg-opacity-20 transition duration-300 ease-in-out">
//     <div className="text-4xl mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold mb-2">{title}</h3>
//     <p className="text-gray-300">{description}</p>
//   </div>
// );

// export default LandingPage;



// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import Portrait from "./Portrait.jpg";
// import PortraitEnhanced from "./PortraitEnhanced.jpg";
// import illustration from "./illustration.jpg"
// import illustrationenhanced from "./illustrationenhanced.jpg";
// import landscape from "./landscape.jpg";
// import landscapeenhanced from "./enhancedlandscape.jpg";
// import videogame from "./videogame.jpg";
// import videogameenhanced from "./videogameenhanced.jpg";
// import scifint from "./scifi.jpg";
// import scifintenhanced from "./scifienhanced.jpg";
// import fantasy from "./fantasy.jpg";
// import fantasyEnhanced from "./fantasyenhanced.jpg";
// import architecture from "./architecture.jpg";
// import architectureenhanced from "./architectureenhanced.jpg";
// const LandingPage = () => {
//   const [currentExample, setCurrentExample] = useState(0);
//   const [sliderPosition, setSliderPosition] = useState(50);
//   const [selectedCategory, setSelectedCategory] = useState('All');

//   const examples = [
//     {
//       before: Portrait,
//       after: PortraitEnhanced,
//       category: "Portraits",
//       description: "Stellar Portrait Boost"
//     },
//     {
//       before: illustration,
//       after: illustrationenhanced,
//       category: "Illustrations",
//       description: "Upscale and Enhance Your Generated Art"
//     },
//     {
//       before: landscape,
//       after: landscapeenhanced,
//       category: "Nature & Landscapes",
//       description: "Deliver Vibrancy to your Landscape"
//     },
//     {
//       before: videogame,
//       after: videogameenhanced,
//       category: "Video games",
//       description: "Enable Unprecendented Digital Quality"
//     },
//     {
//       before: scifint,
//       after: scifintenhanced,
//       category: "Science Fiction",
//       description: "Create and Super lift Sci Fi"
//     },
//     {
//       before: fantasy,
//       after: fantasyEnhanced,
//       category: "Fantasy",
//       description: "Make your Fantasy world and Iterate"
//     },
//     {
//       before: architecture,
//       after: architectureenhanced,
//       category: "Architecture",
//       description: "Stunning Architectural Clarity"
//     },
//   ];

//   const categories = [
//     "All", "Portraits", "Illustrations", "Video games", "Nature & Landscapes", "Science Fiction", "Fantasy",
//     "Architecture"
//   ];

//   const faqs = [
//     { 
//       question: "How does Whiteberry's AI upscaler & enhancer work?",
//       answer: "Whiteberry uses advanced machine learning algorithms to analyze and improve image quality, adding detail and sharpness while maintaining natural looks."
//     },
//     { 
//       question: "Can Whiteberry generate images as well as enhance them?",
//       answer: "Yes! Whiteberry is not just an enhancement tool. It can also generate entirely new images based on text prompts, combining its upscaling and creative capabilities."
//     },
//     { 
//       question: "Is Whiteberry suitable for beginners?",
//       answer: "Absolutely! Whiteberry is designed with an intuitive interface that makes it easy for beginners to use."
//     },
//     { 
//       question: "How much does Whiteberry AI cost?",
//       answer: "A simple 25$ a month, and if you choose yearly 12.5$ a month !"
//     },
//     { 
//       question: "Is the payment service secure?",
//       answer: "Yes, we use Stripe, a leading payment platform known for safety and transparency."
//     },
//     {
//       question: "How can I contact Whiteberry support?",
//       answer: "You can reach our support team at kendiffuse2@gmail.com. We aim to respond to all inquiries within 24 hours."
//     },
//   ];

//   const [openFAQ, setOpenFAQ] = useState(null);

//   const handleSliderChange = (e) => {
//     setSliderPosition(e.target.value);
//   };

//   const filteredExamples = selectedCategory === 'All' 
//     ? examples 
//     : examples.filter(ex => ex.category === selectedCategory);

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
//       <section className="w-full max-w-6xl px-4 py-12">
//         <div className="text-center mb-12">
//           <h1 className="text-5xl font-bold mb-4">
//             The Image <span className="text-blue-400">Generator</span>,
//             <br />
//             <span className="text-teal-300">Enhancer</span> & <span className="text-blue-400">Transformer</span>
//             <br />
//             that feels like Magic ✨
//           </h1>
//           <p className="text-xl text-gray-300 mb-8">
//             The most advanced AI tech to generate, enhance, and transform images. Whiteberry can create and reimagine details guided by your own prompt and parameters!
//           </p>
//           <div className="flex flex-col items-center">
//             <Link
//               to="/signin"
//               className="px-12 py-5 text-2xl bg-gradient-to-r from-blue-500 to-teal-400 text-white font-bold rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
//             >
//               Start For Free
//             </Link>
//             <p className="mt-4 text-lg text-gray-300 font-medium">No Credit Card Required</p>
//           </div>
//         </div>
//         <div className="mb-16">
//           <h2 className="text-3xl font-semibold mb-8 text-center">Before / After Examples</h2>
//           <div className="flex flex-wrap justify-center space-x-2 space-y-2 mb-4">
//             {categories.map((category, index) => (
//               <button
//                 key={index}
//                 onClick={() => setSelectedCategory(category)}
//                 className={`px-4 py-2 rounded-full text-sm ${
//                   selectedCategory === category
//                     ? 'bg-blue-500 text-white'
//                     : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
//                 }`}
//               >
//                 {category}
//               </button>
//             ))}
//           </div>
//           <div className="relative">
//             <div className="flex justify-between items-center mb-4">
//               <button 
//                 onClick={() => setCurrentExample((prev) => (prev - 1 + filteredExamples.length) % filteredExamples.length)} 
//                 className="text-blue-400"
//               >
//                 &lt; Previous
//               </button>
//               <span className="text-lg font-semibold">{filteredExamples[currentExample % filteredExamples.length].category}</span>
//               <button 
//                 onClick={() => setCurrentExample((prev) => (prev + 1) % filteredExamples.length)} 
//                 className="text-blue-400"
//               >
//                 Next &gt;
//               </button>
//             </div>
//             <div className="relative w-full h-96 overflow-hidden rounded-lg">
//               <img 
//                 src={filteredExamples[currentExample % filteredExamples.length].before} 
//                 alt="Before" 
//                 className="absolute top-0 left-0 w-full h-full object-cover"
//               />
//               <div 
//                 className="absolute top-0 left-0 w-full h-full overflow-hidden"
//                 style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
//               >
//                 <img 
//                   src={filteredExamples[currentExample % filteredExamples.length].after} 
//                   alt="After" 
//                   className="absolute top-0 left-0 w-full h-full object-cover"
//                 />
//               </div>
//               <div 
//                 className="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize"
//                 style={{ left: `${sliderPosition}%` }}
//               ></div>
//             </div>
//             <input
//               type="range"
//               min="0"
//               max="100"
//               value={sliderPosition}
//               onChange={handleSliderChange}
//               className="w-full mt-4"
//             />
//             <p className="text-center mt-4 text-gray-300">{filteredExamples[currentExample % filteredExamples.length].description}</p>
//           </div>
//         </div>
//       </section>

//       <section className="w-full max-w-6xl px-4 py-12">
//         <h2 className="text-3xl font-semibold mb-8 text-center">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <div key={index} className="bg-gray-800 p-4 rounded-lg">
//               <button
//                 className="flex justify-between items-center w-full text-left"
//                 onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
//               >
//                 <h3 className="text-lg font-semibold">{faq.question}</h3>
//                 <span>{openFAQ === index ? '−' : '+'}</span>
//               </button>
//               {openFAQ === index && (
//                 <p className="mt-2 text-gray-300">{faq.answer}</p>
//               )}
//             </div>
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default LandingPage;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Bakery from "./bakery.jpg";
import BakeryEnhanced from "./bakeryenhanced.jpg";
import Portrait from "./Portrait.jpg";
import PortraitEnhanced from "./PortraitEnhanced.jpg";
import illustration from "./illustration.jpg"
import illustrationenhanced from "./illustrationenhanced.jpg";
import landscape from "./landscape.jpg";
import landscapeenhanced from "./enhancedlandscape.jpg";
import videogame from "./videogame.jpg";
import videogameenhanced from "./videogameenhanced.jpg";
import scifint from "./scifi.jpg";
import scifintenhanced from "./scifienhanced.jpg";
import fantasy from "./fantasy.jpg";
import fantasyEnhanced from "./fantasyenhanced.jpg";
import architecture from "./architecture.jpg";
import architectureenhanced from "./architectureenhanced.jpg";
import cafe from "./cafe.jpg";
import cafeenhanced from "./cafeenhanced.jpg";
const LandingPage = () => {
  const [currentExample, setCurrentExample] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(50);
  const [selectedCategory, setSelectedCategory] = useState('All');

  const examples = [
    {
      before: Bakery,
      after: BakeryEnhanced,
      category: "Bakery",
      description: "prompt: Delicious and Steamy Crosssaint in Fall Theme Setting, Warm, Hyper Realistic"
    },
    {
      before: cafe,
      after: cafeenhanced,
      category: "Cafe",
      description: "prompt: Beautiful Fall Themed Cafe, Hot Delicious Latte with Heart Design on its Foam"
    },
    {
      before: Portrait,
      after: PortraitEnhanced,
      category: "Portraits",
      description: "prompt: Beautiful Model, Photo Shoot, Sunlight, Hyper Realistic"
    },
    {
      before: illustration,
      after: illustrationenhanced,
      category: "Illustrations",
      description: "prompt: Beautiful Colorful Illustration with Trees and Waves"
    },
    {
      before: landscape,
      after: landscapeenhanced,
      category: "Nature & Landscapes",
      description: "prompt: Turkish style home on top of mountain overlooking the blue sea, beautiful, Hyper Realistic, sunny"
    },
    {
      before: videogame,
      after: videogameenhanced,
      category: "Video games",
      description: "prompt: Scary Powerful Video Game Villain, Excitement, Daunting"
    },
    {
      before: scifint,
      after: scifintenhanced,
      category: "Science Fiction",
      description: "prompt: Sci-Fi Themed Battle Ship Descent"
    },
    {
      before: fantasy,
      after: fantasyEnhanced,
      category: "Fantasy",
      description: "prompt: Beautiful Fantasy of 3d Rendering, Fall Themed"
    },
    {
      before: architecture,
      after: architectureenhanced,
      category: "Architecture",
      description: "prompt: Stunning Indoor Architectural Masterpiece"
    },
  ];

  const categories = [
    "All", "Bakery","Cafe","Portraits", "Illustrations", "Video games", "Nature & Landscapes", "Science Fiction", "Fantasy",
    "Architecture"
  ];

  const faqs = [
    { 
      question: "How does Whiteberry's AI upscaler & enhancer work?",
      answer: "Whiteberry uses advanced machine learning algorithms to analyze and improve image quality, adding detail and sharpness while maintaining natural looks."
    },
    { 
      question: "Are new iterations coming?",
      answer: "Absolutely, iteration speed is and will be very fast for this small company."
    },
    { 
      question: "Can Whiteberry generate images as well as enhance them?",
      answer: "Yes! Whiteberry is not just an enhancement tool. It can also generate entirely new images based on text prompts, combining its upscaling and creative capabilities."
    },
    { 
      question: "Is Whiteberry suitable for beginners?",
      answer: "Absolutely! Whiteberry is designed with an intuitive interface that makes it easy for beginners to use."
    },
    { 
      question: "How much does Whiteberry AI cost? What about compared to other image services",
      answer: "12.50$ a month if yearly plan, 25$ if monthly. A great discount relative to other generation services !"
    },
    { 
      question: "Is the payment service secure?",
      answer: "Yes, we use Stripe, a leading payment platform known for safety and transparency."
    },
    {
      question: "I can download my enhanced image but not my original one, what is wrong?",
      answer: "If you have a period as the very last character of your prompt, remove that and try again, if that doesn't work then contact us @ kendiffuse2@gmail.com"
    },
    
    {
      question: "How can I contact Whiteberry support?",
      answer: "You can reach our support team at kendiffuse2@gmail.com. We aim to respond to all inquiries within 24 hours."
    },
  ];

  const [openFAQ, setOpenFAQ] = useState(null);

  const handleSliderChange = (e) => {
    setSliderPosition(e.target.value);
  };

  const filteredExamples = selectedCategory === 'All' 
    ? examples 
    : examples.filter(ex => ex.category === selectedCategory);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
      <section className="w-full max-w-6xl px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-5xl font-bold mb-4">
            The Image <span className="text-blue-400">Generator</span>,
            <br />
            <span className="text-teal-300">Enhancer</span> & <span className="text-blue-400">Transformer</span>
            <br />
            that feels like Magic ✨
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            The most advanced AI tech to generate, enhance, and transform images. Whiteberry can create and reimagine details guided by your own prompt and parameters!
          </p>
          <div className="flex flex-col items-center">
            <Link
              to="/signin"
              className="px-12 py-5 text-2xl bg-gradient-to-r from-blue-500 to-teal-400 text-white font-bold rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
            >
              Start For Free
            </Link>
            <p className="mt-4 text-lg text-gray-300 font-medium">No Credit Card Required</p>
          </div>
        </div>
        <div className="mb-16">
          <h2 className="text-3xl font-semibold mb-8 text-center">Before / After Examples</h2>
          <div className="flex flex-wrap justify-center space-x-2 space-y-2 mb-4">
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full text-sm ${
                  selectedCategory === category
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
          <div className="relative">
            <div className="flex justify-between items-center mb-4">
              <button 
                onClick={() => setCurrentExample((prev) => (prev - 1 + filteredExamples.length) % filteredExamples.length)} 
                className="text-blue-400"
              >
                &lt; Previous
              </button>
              <span className="text-lg font-semibold">{filteredExamples[currentExample % filteredExamples.length].category}</span>
              <button 
                onClick={() => setCurrentExample((prev) => (prev + 1) % filteredExamples.length)} 
                className="text-blue-400"
              >
                Next &gt;
              </button>
            </div>
            <div className="relative w-full aspect-[16/9] overflow-hidden rounded-lg">
              <img 
                src={filteredExamples[currentExample % filteredExamples.length].before} 
                alt="Before" 
                className="absolute top-0 left-0 w-full h-full object-contain select-none"
              />
              <div 
                className="absolute top-0 left-0 w-full h-full overflow-hidden"
                style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
              >
                <img 
                  src={filteredExamples[currentExample % filteredExamples.length].after} 
                  alt="After" 
                  className="absolute top-0 left-0 w-full h-full object-contain select-none"
                />
              </div>
              <div 
                className="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize"
                style={{ left: `${sliderPosition}%` }}
              ></div>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              value={sliderPosition}
              onChange={handleSliderChange}
              className="w-full mt-4"
            />
            <p className="text-center mt-4 text-gray-300">{filteredExamples[currentExample % filteredExamples.length].description}</p>
          </div>
        </div>
      </section>

      <section className="w-full max-w-6xl px-4 py-12">
        <h2 className="text-3xl font-semibold mb-8 text-center">Frequently Asked Questions</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-gray-800 p-4 rounded-lg">
              <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
              >
                <h3 className="text-lg font-semibold">{faq.question}</h3>
                <span>{openFAQ === index ? '−' : '+'}</span>
              </button>
              {openFAQ === index && (
                <p className="mt-2 text-gray-300">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
