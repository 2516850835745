// import React, { useState, useEffect, useRef } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Upload, Image as ImageIcon, Wand2, Trash2, ChevronUp, ChevronDown, Plus, Download, Undo, Redo, Sparkles } from 'lucide-react';
// import { Lambda } from '@aws-sdk/client-lambda';
// import SubscriptionModal from './subscriptionmodel';
// import ErrorModal from './ErrorModal';
// import imageCompression from 'browser-image-compression';
// import image1 from './cat_saiyan.png';
// import image2 from './Blue_Sunflower.png';
// import image3 from './Croc_Humanoid.png';

// const lambda = new Lambda({
//   region: 'us-east-1',
//   credentials: {
    
//     accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY_ID,
    
//     secretAccessKey: process.env.REACT_APP_LAMBDA_SECRET_KEY_ID
//   }
// });

// const convertToWebP = async (file) => {
//   const options = {
//     maxSizeMB: 1,
//     maxWidthOrHeight: 1920,
//     useWebWorker: true,
//     fileType: 'image/webp'
//   };

//   try {
//     const compressedFile = await imageCompression(file, options);
//     return new File([compressedFile], file.name.replace(/\.[^/.]+$/, ".webp"), { type: 'image/webp' });
//   } catch (error) {
//     console.error("Error converting to WebP:", error);
//     return file;
//   }
// };

// const checkUserPermission = async (action, setIsModalOpen, setIsErrorModalOpen) => {
//   const userEmail = localStorage.getItem('userEmail');
//   console.log("Image Gen email is, ", userEmail);
  
//   try {
    
//     const freeTrialResponse = await fetch(process.env.REACT_APP_FREE_TRIAL_API_GW, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ email: userEmail }),
//     });

//     const freeTrialData = await freeTrialResponse.json();
//     const parsedFreeTrialData = JSON.parse(freeTrialData.body);

//     if (parsedFreeTrialData.message === "Free trial expired") {
      
//       console.log('Free trial exhausted, checking subscription');
//       const subscriptionResponse = await fetch(process.env.REACT_APP_IMAGE_GEN_SUB_CHECK, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email: userEmail,
//           action: action
//         }),
//       });

//       const data = await subscriptionResponse.json();
//       console.log("data is ", data)
//       if (data.statusCode === 403){
//         console.error('User not Subscribed')
//         setIsModalOpen(true);
//         return false;
//       }
//       if (data.statusCode === 404) {
//         console.error('Servers overheating');
//         setIsErrorModalOpen(true);
//         return false;
//       }
      
//       return true;
//     } else if(parsedFreeTrialData.message == "Credit used successfully" || parsedFreeTrialData.message == "Free trial started" ){
//       return true;
//     } else {
//       console.error('Unexpected response from free trial API');
//       return false;
//     }
//   } catch (error) {
//     console.error('Error checking user permission:', error);
//     return false;
//   }
// };

// const ImageGenerationPage = () => {
//   const [images, setImages] = useState([]);
//   const [prompts, setPrompts] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
//   const [focusedImage, setFocusedImage] = useState(null);
//   const [isGenerating, setIsGenerating] = useState(false);
//   const [isLooping, setIsLooping] = useState(false);
//   const [enhancedImages, setEnhancedImages] = useState({});
//   const [isConverting, setIsConverting] = useState(false);
  
//   const handleFileUpload = async (event) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       console.error('User not subscribed or our Servers are overheated, check back at 6pm EST for reset');
//       return;
//     }

//     const files = Array.from(event.target.files);
//     setIsConverting(true);
//     try {
//       const newImages = await Promise.all(files.map(async file => {
//         let webpFile = file;
//         if (file.type !== 'image/webp') {
//           webpFile = await convertToWebP(file);
//         }
//         return {
//           type: 'uploaded',
//           src: URL.createObjectURL(webpFile),
//           name: webpFile.name,
//           file: webpFile,
//           edits: [],
//           undoStack: [],
//           redoStack: []
//         };
//       }));
//       setImages([...images, ...newImages]);
//       setPrompts([...prompts, ...Array(newImages.length).fill('')]);
//     } catch (error) {
//       console.error('Error converting images:', error);
//     } finally {
//       setIsConverting(false);
//     }
//   };

//   const handleGenerateImage = async (prompt) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       return;
//     }

//     setIsGenerating(true);
//     try {
//       const params = {
//         FunctionName: 'FluxProImageGeneration',
//         InvocationType: 'RequestResponse',
//         Payload: JSON.stringify({ prompt: prompt })
//       };
  
//       const response = await lambda.invoke(params);
//       const result = JSON.parse(new TextDecoder().decode(response.Payload));
      
//       if (result.statusCode !== 200) {
//         throw new Error(result.body);
//       }
  
//       const body = JSON.parse(result.body);
//       const imageUrl = body.output;
  
//       const imageResponse = await fetch(imageUrl);
//       const imageBlob = await imageResponse.blob();
//       const blobUrl = URL.createObjectURL(imageBlob);
  
//       const newImage = {
//         type: 'generated',
//         src: blobUrl,
//         name: `Generated: ${prompt}`,
//         file: new File([imageBlob], `generated_${Date.now()}.png`, { type: 'image/png' }),
//         edits: [],
//         undoStack: [],
//         redoStack: []
//       };
  
//       setImages(prevImages => [...prevImages, newImage]);
//       setPrompts(prevPrompts => [...prevPrompts, '']);
//     } catch (error) {
//       console.error('Error generating image:', error);
//     } finally {
//       setIsGenerating(false);
//     }
//   };

//   const handleEnhancedImage = async (originalImage, enhancedUrl) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       return;
//     }

//     setEnhancedImages(prev => ({
//       ...prev,
//       [originalImage.src]: enhancedUrl
//     }));
//   };

//   const handleImageFocus = (image) => {
//     setFocusedImage(image);
//   };

//   return (
//     <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-gradient-to-br from-gray-900 to-black text-white">
//       <div className="max-w-7xl mx-auto">
//         <h1 className="text-4xl sm:text-4xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
//           Image Studio
//         </h1>
        
//         {images.length === 0 ? (
//           <InitialOptions 
//             onFileUpload={handleFileUpload} 
//             onGenerateImage={handleGenerateImage}
//             isGenerating={isGenerating}
//             isConverting={isConverting}
//           />
//         ) : (
//           <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8">
//             <div className="w-full lg:w-2/5">
//               <ImageList 
//                 images={images}
//                 prompts={prompts}
//                 setImages={setImages}
//                 setPrompts={setPrompts}
//                 onFocus={handleImageFocus}
//                 isLooping={isLooping}
//                 enhancedImages={enhancedImages}
//               />
              
//               <div className="mt-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
//                 <AddMoreButton onFileUpload={handleFileUpload} isConverting={isConverting} />
//                 <GenerateMoreButton onGenerateImage={handleGenerateImage} isGenerating={isGenerating} />
//               </div>
//               {/* <motion.button
//                 whileHover={{ scale: 1.02 }}
//                 whileTap={{ scale: 0.98 }}
//                 onClick={() => console.log('Create image set with', images, prompts)}
//                 className="mt-6 w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 shadow-lg text-sm sm:text-base"
//               >
//                 Save Image Set
//               </motion.button> */}
//             </div>
//             <div className="w-full lg:w-3/5">
//               <FocusedImageView 
//                 image={focusedImage} 
//                 onEnhance={handleEnhancedImage}
//                 setIsModalOpen={setIsModalOpen}
//                 setIsErrorModalOpen={setIsErrorModalOpen}
//               />
//             </div>
//           </div>
//         )}
//       </div>
//       <ExamplesSection />
//       <SubscriptionModal 
//         isOpen={isModalOpen} 
//         onClose={() => setIsModalOpen(false)} 
//       />
//       <ErrorModal
//         isOpen={isErrorModalOpen}
//         onClose={() => setIsErrorModalOpen(false)}
//         message="Servers overheating, try again later!"
//       />
//     </div>
//   );
// };

// const InitialOptions = ({ onFileUpload, onGenerateImage, isGenerating, isConverting }) => {
//   const [prompt, setPrompt] = useState('');

//   const handleGenerate = () => {
//     if (prompt.trim()) {
//       onGenerateImage(prompt);
//       setPrompt('');
//     }
//   };

//   return (
//     <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 shadow-xl border border-gray-200 border-opacity-20">
//       <input
//         type="text"
//         value={prompt}
//         onChange={(e) => setPrompt(e.target.value)}
//         placeholder="Describe the image you want to generate..."
//         className="w-full p-4 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-base sm:text-lg mb-4"
//       />
//       <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
//         <motion.button
//           whileHover={{ scale: 1.02 }}
//           whileTap={{ scale: 0.98 }}
//           onClick={handleGenerate}
//           disabled={isGenerating || !prompt.trim()}
//           className={`flex-1 bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 text-sm sm:text-base ${
//             isGenerating || !prompt.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
//           }`}
//         >
//           {isGenerating ? 'Generating...' : 'Generate'}
//         </motion.button>
//         <motion.div
//           whileHover={{ scale: 1.02 }}
//           whileTap={{ scale: 0.98 }}
//           className="flex-1"
//         >
//           <input
//             type="file"
//             multiple
//             accept="image/*"
//             className="hidden"
//             id="file-upload"
//             onChange={onFileUpload}
//             disabled={isConverting}
//           />
//           <label
//             htmlFor="file-upload"
//             className={`flex items-center justify-center w-full bg-gray-700 text-white font-semibold py-3 px-6 rounded-full hover:bg-gray-600 transition duration-300 cursor-pointer text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
//           >
//             <Upload className="mr-2" size={18} />
//             {isConverting ? 'Converting...' : 'Upload Image'}
//           </label>
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// const ImageList = ({ images, setImages, onFocus, enhancedImages }) => {
//   const handleRemoveImage = (index) => {
//     setImages(images.filter((_, i) => i !== index));
//   };

//   const handleMoveImage = (index, direction) => {
//     const newImages = [...images];
//     if (direction === 'up' && index > 0) {
//       [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
//     } else if (direction === 'down' && index < images.length - 1) {
//       [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
//     }
//     setImages(newImages);
//   };

//   return (
//     <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto pr-4">
//       {images.map((image, index) => (
//         <motion.div 
//           key={index}
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.3 }}
//           className="flex items-center bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-3 border border-gray-200 border-opacity-20"
//         >
//           <div className="flex flex-col items-center mr-2">
//             <button onClick={() => handleMoveImage(index, 'up')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300 mb-1">
//               <ChevronUp size={14} className="text-white" />
//             </button>
//             <button onClick={() => handleMoveImage(index, 'down')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300">
//               <ChevronDown size={14} className="text-white" />
//             </button>
//           </div>
//           <img 
//             src={enhancedImages[image.src] || image.src} 
//             alt={image.name} 
//             className="w-20 h-20 object-cover rounded-lg mr-3" 
//           />
//           <div className="flex-grow">
//             <p className="text-gray-200 text-sm font-medium">{image.name}</p>
//             <p className="text-xs text-gray-400">{image.type}</p>
//           </div>
//           <div className="flex space-x-2">
//             <IconButton
//               icon={<Wand2 size={16} />}
//               onClick={() => onFocus({ ...image, action: 'enhance' })}
//               tooltip="Enhance Image"
//             />
//             <IconButton
//               icon={<Download size={16} />}
//               onClick={() => downloadImage(image)}
//               tooltip="Download Image"
//             />
//             <IconButton
//               icon={<Trash2 size={16} />}
//               onClick={() => handleRemoveImage(index)}
//               tooltip="Remove Image"
//             />
//           </div>
//         </motion.div>
//       ))}
//     </div>
//   );
// };

// const IconButton = ({ icon, onClick, tooltip }) => (
//   <div className="relative group">
//     <motion.button
//       whileHover={{ scale: 1.1 }}
//       whileTap={{ scale: 0.9 }}
//       onClick={onClick}
//       className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300"
//     >
//       {React.cloneElement(icon, { className: "text-white", size: 16 })}
//     </motion.button>
//     <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
//       {tooltip}
//     </div>
//   </div>
// );

// const downloadImage = (image) => {
//   const link = document.createElement('a');
//   link.href = image.edits.length > 0 ? image.edits[image.edits.length - 1] : image.src;
  
//   let extension = 'png';
//   if (image.name.includes('.')) {
//     extension = image.name.split('.').pop();
//   } else if (image.type.includes('/')) {
//     extension = image.type.split('/').pop();
//   }
  
//   link.download = `${image.name.split('.')[0]}_edited.${extension}`;
  
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

// const enhanceImage = async (image, setIsModalOpen, setIsErrorModalOpen) => {
//   if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//     console.error('User not authorized to upload images');
//     return;
//   }
//   try {
//     let base64Image;
//     if (image.file) {
//       base64Image = await readFileAsBase64(image.file);
//     } else {
//       const response = await fetch(image.src);
//       const blob = await response.blob();
//       base64Image = await readFileAsBase64(blob);
//     }

//     const params = {
//       FunctionName: 'ClarityUpscaler',
//       InvocationType: 'RequestResponse',
//       Payload: JSON.stringify({ file: base64Image })
//     };

//     const lambdaResponse = await lambda.invoke(params);
//     console.log("lambda response is ", lambdaResponse);
//     const result = JSON.parse(new TextDecoder().decode(lambdaResponse.Payload));
//     console.log("result response is: ", result);
//     if (result.statusCode !== 200) {
//       throw new Error(result.body);
//     }

//     const body = JSON.parse(result.body);
//     return body.output;
//   } catch (error) {
//     console.error('Error enhancing image:', error);
//     throw error;
//   }
// };

// const readFileAsBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result.split(',')[1]);
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// };

// const FocusedImageView = ({ image, onEnhance, setIsModalOpen, setIsErrorModalOpen }) => {
//   const [enhancedImage, setEnhancedImage] = useState(null);
//   const [isEnhancing, setIsEnhancing] = useState(false);
//   const [sliderPosition, setSliderPosition] = useState(50);

//   useEffect(() => {
//     setEnhancedImage(null);
//     setIsEnhancing(false);
//     setSliderPosition(50);
//   }, [image]);

//   const handleEnhance = async () => {
//     if (image?.action === 'enhance' && !enhancedImage) {
//       setIsEnhancing(true);
//       try {
//         const enhancedUrl = await enhanceImage(image, setIsModalOpen, setIsErrorModalOpen);
//         setEnhancedImage(enhancedUrl);
//         onEnhance(image, enhancedUrl);
//       } catch (error) {
//         console.error('Failed to enhance image:', error);
//       } finally {
//         setIsEnhancing(false);
//       }
//     }
//   };

//   const handleSliderChange = (e) => {
//     setSliderPosition(e.target.value);
//   };

//   const handleDownload = async (isEnhanced) => {
//     const imageUrl = isEnhanced ? enhancedImage : image.src;
    
//     try {
//       const response = await fetch(imageUrl);
//       const blob = await response.blob();
      
//       const blobUrl = window.URL.createObjectURL(blob);
      
//       const link = document.createElement('a');
//       link.href = blobUrl;
      
//       let extension = 'png';
//       if (image.name.includes('.')) {
//         extension = image.name.split('.').pop();
//       } else if (image.type.includes('/')) {
//         extension = image.type.split('/').pop();
//       }
      
//       const suffix = isEnhanced ? '_enhanced' : '';
//       link.download = `${image.name.split('.')[0]}${suffix}.${extension}`;
      
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
      
//       window.URL.revokeObjectURL(blobUrl);
//     } catch (error) {
//       console.error('Error downloading image:', error);
//     }
//   };

//   return (
//     <AnimatePresence mode="wait">
//       {image && (
//         <motion.div
//           key={image.src}
//           initial={{ opacity: 0, scale: 0.9 }}
//           animate={{ opacity: 1, scale: 1 }}
//           exit={{ opacity: 0, scale: 0.9 }}
//           transition={{ duration: 0.3 }}
//           className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 h-full flex flex-col justify-center items-center border border-gray-200 border-opacity-20"
//         >
//           <h2 className="text-2xl font-bold text-gray-200 mb-4">Image Enhancement</h2>
//           <div className="relative w-full max-w-2xl aspect-square mb-6">
//             <img 
//               src={image.src} 
//               alt={image.name} 
//               className="absolute top-0 left-0 w-full h-full object-contain rounded-lg" 
//             />
//             {enhancedImage && (
//               <div 
//                 className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-lg"
//                 style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
//               >
//                 <img 
//                   src={enhancedImage} 
//                   alt={`Enhanced ${image.name}`} 
//                   className="absolute top-0 left-0 w-full h-full object-contain" 
//                 />
//               </div>
//             )}
//             {enhancedImage && (
//               <input
//                 type="range"
//                 min="0"
//                 max="100"
//                 value={sliderPosition}
//                 onChange={handleSliderChange}
//                 className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-3/4 z-10"
//               />
//             )}
//           </div>
//           <div className="flex flex-wrap justify-center gap-4">
//             <motion.button
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//               onClick={handleEnhance}
//               disabled={isEnhancing || enhancedImage}
//               className={`bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-2 px-6 rounded-full transition duration-300 text-sm sm:text-base flex items-center ${
//                 isEnhancing || enhancedImage ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
//               }`}
//             >
//               <Sparkles className="mr-2" size={18} />
//               {isEnhancing ? 'Enhancing...' : enhancedImage ? 'Enhanced' : 'Enhance Image'}
//             </motion.button>
//             {enhancedImage && (
//               <>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   onClick={() => handleDownload(true)}
//                   className="bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold py-2 px-6 rounded-full hover:from-green-600 hover:to-teal-600 transition duration-300 text-sm sm:text-base flex items-center"
//                 >
//                   <Download className="mr-2" size={18} />
//                   Download Enhanced
//                 </motion.button>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   onClick={() => handleDownload(false)}
//                   className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-semibold py-2 px-6 rounded-full hover:from-blue-600 hover:to-indigo-600 transition duration-300 text-sm sm:text-base flex items-center"
//                 >
//                   <Download className="mr-2" size={18} />
//                   Download Original
//                 </motion.button>
//               </>
//             )}
//           </div>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// };

// const AddMoreButton = ({ onFileUpload, isConverting }) => (
//   <motion.div
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className="w-full sm:w-auto"
//   >
//     <input
//       type="file"
//       multiple
//       accept="image/*"
//       className="hidden"
//       id="add-more-files"
//       onChange={onFileUpload}
//       disabled={isConverting}
//     />
//     <label
//       htmlFor="add-more-files"
//       className={`flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 cursor-pointer shadow-lg text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
//     >
//       <Plus size={18} className="mr-2" />
//       {isConverting ? 'Converting...' : 'Add More'}
//     </label>
//   </motion.div>
// );

// const GenerateMoreButton = ({ onGenerateImage, isGenerating }) => {
//   const [prompt, setPrompt] = useState('');

//   const handleGenerate = () => {
//     onGenerateImage(prompt);
//     setPrompt('');
//   };

//   return (
//     <motion.div
//       whileHover={{ scale: 1.05 }}
//       whileTap={{ scale: 0.95 }}
//       className="w-full sm:w-auto"
//     >
//       <input
//         type="text"
//         value={prompt}
//         onChange={(e) => setPrompt(e.target.value)}
//         placeholder="Enter your image prompt"
//         className="w-full mb-2 p-3 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-sm"
//       />
//       <button
//         onClick={handleGenerate}
//         disabled={isGenerating}
//         className={`flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 shadow-lg text-sm sm:text-base ${isGenerating ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'}`}
//       >
//         <ImageIcon size={18} className="mr-2" />
//         {isGenerating ? 'Generating...' : 'Generate More'}
//       </button>
//     </motion.div>
//   );
// };

// const ExamplesSection = () => {
//   const exampleImages = [
//     { src: image1, title: "Unlimited Cat", description: "Unlimited Powerful Super Saiyan Cat" },
//     { src: image2, title: "Blue Sunflower", description: "Up Close Shot of Blue Sunflower, hyper realistic, perfect textures, beautiful, happy" },
//     { src: image3, title: "Crocodile Humanoid Ninja", description: "Crocodile Humanoid in Ninja Outfit in Streets of Tokyo, raining, hyper realistic, night time, excitement" },
//   ];

//   return (
//     <div className="mt-16 mb-8">
//       <h2 className="text-4xl sm:text-5xl font-bold text-center mb-10 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
//         Discover AI-Generated Wonders
//       </h2>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//         {exampleImages.map((image, index) => (
//           <motion.div 
//             key={index} 
//             className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl overflow-hidden shadow-xl border border-gray-200 border-opacity-20"
//             whileHover={{ scale: 1.03, borderOpacity: 0.5 }}
//             transition={{ duration: 0.3 }}
//           >
//             <div className="aspect-w-16 aspect-h-9">
//               <img
//                 src={image.src}
//                 alt={image.title}
//                 className="w-full h-full object-cover"
//               />
//             </div>
//             <div className="p-6">
//               <h3 className="text-xl font-semibold text-gray-200 mb-2">{image.title}</h3>
//               <p className="text-gray-300 text-sm">{image.description}</p>
//             </div>
//           </motion.div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ImageGenerationPage;

// import React, { useState, useEffect, useRef } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Upload, Image as ImageIcon, Wand2, Trash2, ChevronUp, ChevronDown, Plus, Download, Undo, Redo, Sparkles } from 'lucide-react';
// import { Lambda } from '@aws-sdk/client-lambda';
// import SubscriptionModal from './subscriptionmodel';
// import ErrorModal from './ErrorModal';
// import imageCompression from 'browser-image-compression';
// import image1 from './cat_saiyan.png';
// import image2 from './Blue_Sunflower.png';
// import image3 from './Croc_Humanoid.png';

// const lambda = new Lambda({
//   region: 'us-east-1',
//   credentials: {
//     accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY_ID,
//     secretAccessKey: process.env.REACT_APP_LAMBDA_SECRET_KEY_ID
//   }
// });

// const convertToWebP = async (file) => {
//   const options = {
//     maxSizeMB: 1,
//     maxWidthOrHeight: 1920,
//     useWebWorker: true,
//     fileType: 'image/webp'
//   };

//   try {
//     const compressedFile = await imageCompression(file, options);
//     return new File([compressedFile], file.name.replace(/\.[^/.]+$/, ".webp"), { type: 'image/webp' });
//   } catch (error) {
//     console.error("Error converting to WebP:", error);
//     return file;
//   }
// };

// const checkUserPermission = async (action, setIsModalOpen, setIsErrorModalOpen) => {
//   const userEmail = localStorage.getItem('userEmail');
//   console.log("Image Gen email is, ", userEmail);
  
//   try {
//     const freeTrialResponse = await fetch(process.env.REACT_APP_FREE_TRIAL_API_GW, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ email: userEmail }),
//     });

//     const freeTrialData = await freeTrialResponse.json();
//     const parsedFreeTrialData = JSON.parse(freeTrialData.body);

//     if (parsedFreeTrialData.message === "Free trial expired") {
//       console.log('Free trial exhausted, checking subscription');
//       const subscriptionResponse = await fetch(process.env.REACT_APP_IMAGE_GEN_SUB_CHECK, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email: userEmail,
//           action: action
//         }),
//       });

//       const data = await subscriptionResponse.json();
//       console.log("data is ", data)
//       if (data.statusCode === 403){
//         console.error('User not Subscribed')
//         setIsModalOpen(true);
//         return false;
//       }
//       if (data.statusCode === 404) {
//         console.error('Servers overheating');
//         setIsErrorModalOpen(true);
//         return false;
//       }
      
//       return true;
//     } else if(parsedFreeTrialData.message == "Credit used successfully" || parsedFreeTrialData.message == "Free trial started" ){
//       return true;
//     } else {
//       console.error('Unexpected response from free trial API');
//       return false;
//     }
//   } catch (error) {
//     console.error('Error checking user permission:', error);
//     return false;
//   }
// };

// const ImageGenerationPage = () => {
//   const [images, setImages] = useState([]);
//   const [prompts, setPrompts] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
//   const [focusedImage, setFocusedImage] = useState(null);
//   const [isGenerating, setIsGenerating] = useState(false);
//   const [isLooping, setIsLooping] = useState(false);
//   const [enhancedImages, setEnhancedImages] = useState({});
//   const [isConverting, setIsConverting] = useState(false);
  
//   const handleFileUpload = async (event) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       console.error('User not subscribed or our Servers are overheated, check back at 6pm EST for reset');
//       return;
//     }

//     const files = Array.from(event.target.files);
//     setIsConverting(true);
//     try {
//       const newImages = await Promise.all(files.map(async file => {
//         let webpFile = file;
//         if (file.type !== 'image/webp') {
//           webpFile = await convertToWebP(file);
//         }
//         return {
//           type: 'uploaded',
//           src: URL.createObjectURL(webpFile),
//           name: webpFile.name,
//           file: webpFile,
//           edits: [],
//           undoStack: [],
//           redoStack: []
//         };
//       }));
//       setImages([...images, ...newImages]);
//       setPrompts([...prompts, ...Array(newImages.length).fill('')]);
//     } catch (error) {
//       console.error('Error converting images:', error);
//     } finally {
//       setIsConverting(false);
//     }
//   };

//   const handleGenerateImage = async (prompt) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       return;
//     }

//     setIsGenerating(true);
//     try {
//       const params = {
//         FunctionName: 'FluxProImageGeneration',
//         InvocationType: 'RequestResponse',
//         Payload: JSON.stringify({ prompt: prompt })
//       };
  
//       const response = await lambda.invoke(params);
//       const result = JSON.parse(new TextDecoder().decode(response.Payload));
      
//       if (result.statusCode !== 200) {
//         throw new Error(result.body);
//       }
  
//       const body = JSON.parse(result.body);
//       const imageUrl = body.output;
  
//       const imageResponse = await fetch(imageUrl);
//       const imageBlob = await imageResponse.blob();
//       const blobUrl = URL.createObjectURL(imageBlob);
  
//       const newImage = {
//         type: 'generated',
//         src: blobUrl,
//         name: `Generated: ${prompt}`,
//         file: new File([imageBlob], `generated_${Date.now()}.png`, { type: 'image/png' }),
//         edits: [],
//         undoStack: [],
//         redoStack: []
//       };
  
//       setImages(prevImages => [...prevImages, newImage]);
//       setPrompts(prevPrompts => [...prevPrompts, '']);
//     } catch (error) {
//       console.error('Error generating image:', error);
//     } finally {
//       setIsGenerating(false);
//     }
//   };

//   const handleEnhancedImage = async (originalImage, enhancedUrl) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       return;
//     }

//     setEnhancedImages(prev => ({
//       ...prev,
//       [originalImage.src]: enhancedUrl
//     }));
//   };

//   const handleImageFocus = (image) => {
//     setFocusedImage(image);
//   };

//   return (
//     <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-gradient-to-br from-gray-900 to-black text-white">
//       <div className="max-w-7xl mx-auto">
//         <h1 className="text-4xl sm:text-5xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
//           Image Studio
//         </h1>
        
//         {images.length === 0 ? (
//           <InitialOptions 
//             onFileUpload={handleFileUpload} 
//             onGenerateImage={handleGenerateImage}
//             isGenerating={isGenerating}
//             isConverting={isConverting}
//           />
//         ) : (
//           <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8">
//             <div className="w-full lg:w-2/5">
//               <ImageList 
//                 images={images}
//                 prompts={prompts}
//                 setImages={setImages}
//                 setPrompts={setPrompts}
//                 onFocus={handleImageFocus}
//                 isLooping={isLooping}
//                 enhancedImages={enhancedImages}
//               />
              
//               <div className="mt-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
//                 <AddMoreButton onFileUpload={handleFileUpload} isConverting={isConverting} />
//                 <GenerateMoreButton onGenerateImage={handleGenerateImage} isGenerating={isGenerating} />
//               </div>
//             </div>
//             <div className="w-full lg:w-3/5">
//               <FocusedImageView 
//                 image={focusedImage} 
//                 onEnhance={handleEnhancedImage}
//                 setIsModalOpen={setIsModalOpen}
//                 setIsErrorModalOpen={setIsErrorModalOpen}
//               />
//             </div>
//           </div>
//         )}
//       </div>
//       <ExamplesSection />
//       <SubscriptionModal 
//         isOpen={isModalOpen} 
//         onClose={() => setIsModalOpen(false)} 
//       />
//       <ErrorModal
//         isOpen={isErrorModalOpen}
//         onClose={() => setIsErrorModalOpen(false)}
//         message="Servers overheating, try again later!"
//       />
//     </div>
//   );
// };

// const InitialOptions = ({ onFileUpload, onGenerateImage, isGenerating, isConverting }) => {
//   const [prompt, setPrompt] = useState('');

//   const handleGenerate = () => {
//     if (prompt.trim()) {
//       onGenerateImage(prompt);
//       setPrompt('');
//     }
//   };

//   return (
//     <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 shadow-xl border border-gray-200 border-opacity-20">
//       <input
//         type="text"
//         value={prompt}
//         onChange={(e) => setPrompt(e.target.value)}
//         placeholder="Describe the image you want to generate..."
//         className="w-full p-4 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-base sm:text-lg mb-4"
//       />
//       <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
//         <motion.button
//           whileHover={{ scale: 1.02 }}
//           whileTap={{ scale: 0.98 }}
//           onClick={handleGenerate}
//           disabled={isGenerating || !prompt.trim()}
//           className={`flex-1 bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 text-sm sm:text-base ${
//             isGenerating || !prompt.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
//           }`}
//         >
//           {isGenerating ? 'Generating...' : 'Generate'}
//         </motion.button>
//         <motion.div
//           whileHover={{ scale: 1.02 }}
//           whileTap={{ scale: 0.98 }}
//           className="flex-1"
//         >
//           <input
//             type="file"
//             multiple
//             accept="image/*"
//             className="hidden"
//             id="file-upload"
//             onChange={onFileUpload}
//             disabled={isConverting}
//           />
//           <label
//             htmlFor="file-upload"
//             className={`flex items-center justify-center w-full bg-gray-700 text-white font-semibold py-3 px-6 rounded-full hover:bg-gray-600 transition duration-300 cursor-pointer text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
//           >
//             <Upload className="mr-2" size={18} />
//             {isConverting ? 'Converting...' : 'Upload Image'}
//           </label>
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// const ImageList = ({ images, setImages, onFocus, enhancedImages }) => {
//   const handleRemoveImage = (index) => {
//     setImages(images.filter((_, i) => i !== index));
//   };

//   const handleMoveImage = (index, direction) => {
//     const newImages = [...images];
//     if (direction === 'up' && index > 0) {
//       [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
//     } else if (direction === 'down' && index < images.length - 1) {
//       [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
//     }
//     setImages(newImages);
//   };

//   return (
//     <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto pr-4">
//       {images.map((image, index) => (
//         <motion.div 
//           key={index}
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.3 }}
//           className="flex items-center bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-3 border border-gray-200 border-opacity-20"
//         >
//           <div className="flex flex-col items-center mr-2">
//             <button onClick={() => handleMoveImage(index, 'up')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300 mb-1">
//               <ChevronUp size={14} className="text-white" />
//             </button>
//             <button onClick={() => handleMoveImage(index, 'down')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300">
//               <ChevronDown size={14} className="text-white" />
//             </button>
//           </div>
//           <img 
//             src={enhancedImages[image.src] || image.src} 
//             alt={image.name} 
//             className="w-20 h-20 object-cover rounded-lg mr-3" 
//           />
//           <div className="flex-grow">
//             <p className="text-gray-200 text-sm font-medium">{image.name}</p>
//             <p className="text-xs text-gray-400">{image.type}</p>
//           </div>
//           <div className="flex space-x-2">
//             <IconButton
//               icon={<Wand2 size={16} />}
//               onClick={() => onFocus({ ...image, action: 'enhance' })}
//               tooltip="Enhance Image"
//             />
//             <IconButton
//               icon={<Download size={16} />}
//               onClick={() => downloadImage(image)}
//               tooltip="Download Image"
//             />
//             <IconButton
//               icon={<Trash2 size={16} />}
//               onClick={() => handleRemoveImage(index)}
//               tooltip="Remove Image"
//             />
//           </div>
//         </motion.div>
//       ))}
//     </div>
//   );
// };

// const IconButton = ({ icon, onClick, tooltip }) => (
//   <div className="relative group">
//     <motion.button
//       whileHover={{ scale: 1.1 }}
//       whileTap={{ scale: 0.9 }}
//       onClick={onClick}
//       className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300"
//     >
//       {React.cloneElement(icon, { className: "text-white", size: 16 })}
//     </motion.button>
//     <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
//       {tooltip}
//     </div>
//   </div>
// );

// const downloadImage = (image) => {
//   const link = document.createElement('a');
//   link.href = image.edits.length > 0 ? image.edits[image.edits.length - 1] : image.src;
  
//   let extension = 'png';
//   if (image.name.includes('.')) {
//     extension = image.name.split('.').pop();
//   } else if (image.type.includes('/')) {
//     extension = image.type.split('/').pop();
//   }
  
//   link.download = `${image.name.split('.')[0]}_edited.${extension}`;
  
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

// const enhanceImage = async (image, setIsModalOpen, setIsErrorModalOpen) => {
//   if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//     console.error('User not authorized to upload images');
//     return;
//   }
//   try {
//     let base64Image;
//     if (image.file) {
//       base64Image = await readFileAsBase64(image.file);
//     } else {
//       const response = await fetch(image.src);
//       const blob = await response.blob();
//       base64Image = await readFileAsBase64(blob);
//     }

//     const params = {
//       FunctionName: 'ClarityUpscaler',
//       InvocationType: 'RequestResponse',
//       Payload: JSON.stringify({ file: base64Image })
//     };

//     const lambdaResponse = await lambda.invoke(params);
//     console.log("lambda response is ", lambdaResponse);
//     const result = JSON.parse(new TextDecoder().decode(lambdaResponse.Payload));
//     console.log("result response is: ", result);
//     if (result.statusCode !== 200) {
//       throw new Error(result.body);
//     }

//     const body = JSON.parse(result.body);
//     return body.output;
//   } catch (error) {
//     console.error('Error enhancing image:', error);
//     throw error;
//   }
// };

// const readFileAsBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result.split(',')[1]);
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// };

// const FocusedImageView = ({ image, onEnhance, setIsModalOpen, setIsErrorModalOpen }) => {
//   const [enhancedImage, setEnhancedImage] = useState(null);
//   const [isEnhancing, setIsEnhancing] = useState(false);
//   const [sliderPosition, setSliderPosition] = useState(50);

//   useEffect(() => {
//     setEnhancedImage(null);
//     setIsEnhancing(false);
//     setSliderPosition(50);
//   }, [image]);

//   const handleEnhance = async () => {
//     if (image?.action === 'enhance' && !enhancedImage) {
//       setIsEnhancing(true);
//       try {
//         const enhancedUrl = await enhanceImage(image, setIsModalOpen, setIsErrorModalOpen);
//         setEnhancedImage(enhancedUrl);
//         onEnhance(image, enhancedUrl);
//       } catch (error) {
//         console.error('Failed to enhance image:', error);
//       } finally {
//         setIsEnhancing(false);
//       }
//     }
//   };

//   const handleSliderChange = (e) => {
//     setSliderPosition(e.target.value);
//   };

//   const handleDownload = async (isEnhanced) => {
//     const imageUrl = isEnhanced ? enhancedImage : image.src;
    
//     try {
//       const response = await fetch(imageUrl);
//       const blob = await response.blob();
      
//       const blobUrl = window.URL.createObjectURL(blob);
      
//       const link = document.createElement('a');
//       link.href = blobUrl;
      
//       let extension = 'png';
//       if (image.name.includes('.')) {
//         extension = image.name.split('.').pop();
//       } else if (image.type.includes('/')) {
//         extension = image.type.split('/').pop();
//       }
      
//       const suffix = isEnhanced ? '_enhanced' : '';
//       link.download = `${image.name.split('.')[0]}${suffix}.${extension}`;
      
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
      
//       window.URL.revokeObjectURL(blobUrl);
//     } catch (error) {
//       console.error('Error downloading image:', error);
//     }
//   };

//   return (
//     <AnimatePresence mode="wait">
//       {image && (
//         <motion.div
//           key={image.src}
//           initial={{ opacity: 0, scale: 0.9 }}
//           animate={{ opacity: 1, scale: 1 }}
//           exit={{ opacity: 0, scale: 0.9 }}
//           transition={{ duration: 0.3 }}
//           className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 h-full flex flex-col justify-center items-center border border-gray-200 border-opacity-20"
//         >
//           <h2 className="text-2xl font-bold text-gray-200 mb-4">Image Enhancement</h2>
//           <div className="relative w-full max-w-2xl aspect-square mb-6">
//             <img 
//               src={image.src} 
//               alt={image.name} 
//               className="absolute top-0 left-0 w-full h-full object-contain rounded-lg" 
//             />
//             {enhancedImage && (
//               <div 
//                 className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-lg"
//                 style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
//               >
//                 <img 
//                   src={enhancedImage} 
//                   alt={`Enhanced ${image.name}`} 
//                   className="absolute top-0 left-0 w-full h-full object-contain" 
//                 />
//               </div>
//             )}
//             {enhancedImage && (
//               <input
//                 type="range"
//                 min="0"
//                 max="100"
//                 value={sliderPosition}
//                 onChange={handleSliderChange}
//                 className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-3/4 z-10"
//               />
//             )}
//           </div>
//           <div className="flex flex-wrap justify-center gap-4">
//             <motion.button
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//               onClick={handleEnhance}
//               disabled={isEnhancing || enhancedImage}
//               className={`bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-2 px-6 rounded-full transition duration-300 text-sm sm:text-base flex items-center ${
//                 isEnhancing || enhancedImage ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
//               }`}
//             >
//               <Sparkles className="mr-2" size={18} />
//               {isEnhancing ? 'Enhancing...' : enhancedImage ? 'Enhanced' : 'Enhance Image'}
//             </motion.button>
//             {enhancedImage && (
//               <>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   onClick={() => handleDownload(true)}
//                   className="bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold py-2 px-6 rounded-full hover:from-green-600 hover:to-teal-600 transition duration-300 text-sm sm:text-base flex items-center"
//                 >
//                   <Download className="mr-2" size={18} />
//                   Download Enhanced
//                 </motion.button>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   onClick={() => handleDownload(false)}
//                   className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-semibold py-2 px-6 rounded-full hover:from-blue-600 hover:to-indigo-600 transition duration-300 text-sm sm:text-base flex items-center"
//                 >
//                   <Download className="mr-2" size={18} />
//                   Download Original
//                 </motion.button>
//               </>
//             )}
//           </div>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// };

// const AddMoreButton = ({ onFileUpload, isConverting }) => (
//   <motion.div
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className="w-full sm:w-auto"
//   >
//     <input
//       type="file"
//       multiple
//       accept="image/*"
//       className="hidden"
//       id="add-more-files"
//       onChange={onFileUpload}
//       disabled={isConverting}
//     />
//     <label
//       htmlFor="add-more-files"
//       className={`flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 cursor-pointer shadow-lg text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
//     >
//       <Plus size={18} className="mr-2" />
//       {isConverting ? 'Converting...' : 'Add More'}
//     </label>
//   </motion.div>
// );

// const GenerateMoreButton = ({ onGenerateImage, isGenerating }) => {
//   const [prompt, setPrompt] = useState('');

//   const handleGenerate = () => {
//     onGenerateImage(prompt);
//     setPrompt('');
//   };

//   return (
//     <motion.div
//       whileHover={{ scale: 1.05 }}
//       whileTap={{ scale: 0.95 }}
//       className="w-full sm:w-auto"
//     >
//       <input
//         type="text"
//         value={prompt}
//         onChange={(e) => setPrompt(e.target.value)}
//         placeholder="Enter your image prompt"
//         className="w-full mb-2 p-3 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-sm"
//       />
//       <button
//         onClick={handleGenerate}
//         disabled={isGenerating}
//         className={`flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 shadow-lg text-sm sm:text-base ${isGenerating ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'}`}
//       >
//         <ImageIcon size={18} className="mr-2" />
//         {isGenerating ? 'Generating...' : 'Generate More'}
//       </button>
//     </motion.div>
//   );
// };

// const ExamplesSection = () => {
//   const exampleImages = [
//     { src: image1, title: "Unlimited Cat", description: "Unlimited Powerful Super Saiyan Cat" },
//     { src: image2, title: "Blue Sunflower", description: "Up Close Shot of Blue Sunflower, hyper realistic, perfect textures, beautiful, happy" },
//     { src: image3, title: "Crocodile Humanoid Ninja", description: "Crocodile Humanoid in Ninja Outfit in Streets of Tokyo, raining, hyper realistic, night time, excitement" },
//   ];

//   return (
//     <div className="mt-16 mb-8">
//       <h2 className="text-4xl sm:text-5xl font-bold text-center mb-10 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
//         Discover AI-Generated Wonders
//       </h2>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//         {exampleImages.map((image, index) => (
//           <motion.div 
//             key={index} 
//             className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl overflow-hidden shadow-xl border border-gray-200 border-opacity-20"
//             whileHover={{ scale: 1.03, borderOpacity: 0.5 }}
//             transition={{ duration: 0.3 }}
//           >
//             <div className="aspect-w-16 aspect-h-9">
//               <img
//                 src={image.src}
//                 alt={image.title}
//                 className="w-full h-full object-cover"
//               />
//             </div>
//             <div className="p-6">
//               <h3 className="text-xl font-semibold text-gray-200 mb-2">{image.title}</h3>
//               <p className="text-gray-300 text-sm">{image.description}</p>
//             </div>
//           </motion.div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ImageGenerationPage;
//2
// import React, { useState, useEffect, useRef } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Upload, Image as ImageIcon, Wand2, Trash2, ChevronUp, ChevronDown, Plus, Download, Undo, Redo, Sparkles } from 'lucide-react';
// import { Lambda } from '@aws-sdk/client-lambda';
// import SubscriptionModal from './subscriptionmodel';
// import ErrorModal from './ErrorModal';
// import imageCompression from 'browser-image-compression';
// import image1 from './cat_saiyan.png';
// import image2 from './Blue_Sunflower.png';
// import image3 from './Croc_Humanoid.png';

// const lambda = new Lambda({
//   region: 'us-east-1',
//   credentials: {
//     accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY_ID,
//     secretAccessKey: process.env.REACT_APP_LAMBDA_SECRET_KEY_ID
//   }
// });

// const convertToWebP = async (file) => {
//   const options = {
//     maxSizeMB: 1,
//     maxWidthOrHeight: 1920,
//     useWebWorker: true,
//     fileType: 'image/webp'
//   };

//   try {
//     const compressedFile = await imageCompression(file, options);
//     return new File([compressedFile], file.name.replace(/\.[^/.]+$/, ".webp"), { type: 'image/webp' });
//   } catch (error) {
//     console.error("Error converting to WebP:", error);
//     return file;
//   }
// };

// const checkUserPermission = async (action, setIsModalOpen, setIsErrorModalOpen) => {
//   const userEmail = localStorage.getItem('userEmail');
//   console.log("Image Gen email is, ", userEmail);
  
//   try {
//     const freeTrialResponse = await fetch(process.env.REACT_APP_FREE_TRIAL_API_GW, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ email: userEmail }),
//     });

//     const freeTrialData = await freeTrialResponse.json();
//     const parsedFreeTrialData = JSON.parse(freeTrialData.body);

//     if (parsedFreeTrialData.message === "Free trial expired") {
//       console.log('Free trial exhausted, checking subscription');
//       const subscriptionResponse = await fetch(process.env.REACT_APP_IMAGE_GEN_SUB_CHECK, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email: userEmail,
//           action: action
//         }),
//       });

//       const data = await subscriptionResponse.json();
//       console.log("data is ", data)
//       if (data.statusCode === 403){
//         console.error('User not Subscribed')
//         setIsModalOpen(true);
//         return false;
//       }
//       if (data.statusCode === 404) {
//         console.error('Servers overheating');
//         setIsErrorModalOpen(true);
//         return false;
//       }
      
//       return true;
//     } else if(parsedFreeTrialData.message == "Credit used successfully" || parsedFreeTrialData.message == "Free trial started" ){
//       return true;
//     } else {
//       console.error('Unexpected response from free trial API');
//       return false;
//     }
//   } catch (error) {
//     console.error('Error checking user permission:', error);
//     return false;
//   }
// };

// const ProgressIndicator = ({ duration, isActive, text }) => {
//   const [progress, setProgress] = useState(0);

//   useEffect(() => {
//     let interval;
//     if (isActive) {
//       interval = setInterval(() => {
//         setProgress((oldProgress) => {
//           const newProgress = oldProgress + (100 / (duration * 10));
//           return newProgress >= 100 ? 100 : newProgress;
//         });
//       }, 100);
//     }
//     return () => clearInterval(interval);
//   }, [isActive, duration]);

//   return (
//     <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
//       <div
//         className="bg-blue-600 h-2.5 rounded-full"
//         style={{ width: `${progress}%` }}
//       ></div>
//       <p className="text-sm text-gray-300 mt-2">{text}</p>
//     </div>
//   );
// };

// const ImageGenerationPage = () => {
//   const [images, setImages] = useState([]);
//   const [prompts, setPrompts] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
//   const [focusedImage, setFocusedImage] = useState(null);
//   const [isGenerating, setIsGenerating] = useState(false);
//   const [isLooping, setIsLooping] = useState(false);
//   const [enhancedImages, setEnhancedImages] = useState({});
//   const [isConverting, setIsConverting] = useState(false);
//   const [generatingProgress, setGeneratingProgress] = useState(false);
  
//   const handleFileUpload = async (event) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       console.error('User not subscribed or our Servers are overheated, check back at 6pm EST for reset');
//       return;
//     }

//     const files = Array.from(event.target.files);
//     setIsConverting(true);
//     try {
//       const newImages = await Promise.all(files.map(async file => {
//         let webpFile = file;
//         if (file.type !== 'image/webp') {
//           webpFile = await convertToWebP(file);
//         }
//         return {
//           type: 'uploaded',
//           src: URL.createObjectURL(webpFile),
//           name: webpFile.name,
//           file: webpFile,
//           edits: [],
//           undoStack: [],
//           redoStack: []
//         };
//       }));
//       setImages([...images, ...newImages]);
//       setPrompts([...prompts, ...Array(newImages.length).fill('')]);
//     } catch (error) {
//       console.error('Error converting images:', error);
//     } finally {
//       setIsConverting(false);
//     }
//   };

//   const handleGenerateImage = async (prompt) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       return;
//     }

//     setIsGenerating(true);
//     setGeneratingProgress(true);
//     try {
//       const params = {
//         FunctionName: 'FluxProImageGeneration',
//         InvocationType: 'RequestResponse',
//         Payload: JSON.stringify({ prompt: prompt })
//       };
  
//       const response = await lambda.invoke(params);
//       const result = JSON.parse(new TextDecoder().decode(response.Payload));
      
//       if (result.statusCode !== 200) {
//         throw new Error(result.body);
//       }
  
//       const body = JSON.parse(result.body);
//       const imageUrl = body.output;
  
//       const imageResponse = await fetch(imageUrl);
//       const imageBlob = await imageResponse.blob();
//       const blobUrl = URL.createObjectURL(imageBlob);
  
//       const newImage = {
//         type: 'generated',
//         src: blobUrl,
//         name: `Generated: ${prompt}`,
//         file: new File([imageBlob], `generated_${Date.now()}.png`, { type: 'image/png' }),
//         edits: [],
//         undoStack: [],
//         redoStack: []
//       };
  
//       setImages(prevImages => [...prevImages, newImage]);
//       setPrompts(prevPrompts => [...prevPrompts, '']);
//     } catch (error) {
//       console.error('Error generating image:', error);
//     } finally {
//       setIsGenerating(false);
//       setGeneratingProgress(false);
//     }
//   };

//   const handleEnhancedImage = async (originalImage, enhancedUrl) => {
//     if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//       return;
//     }

//     setEnhancedImages(prev => ({
//       ...prev,
//       [originalImage.src]: enhancedUrl
//     }));
//   };

//   const handleImageFocus = (image) => {
//     setFocusedImage(image);
//   };

//   return (
//     <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-gradient-to-br from-gray-900 to-black text-white">
//       <div className="max-w-7xl mx-auto">
//         <h1 className="text-4xl sm:text-5xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
//           Image Studio
//         </h1>
        
//         {images.length === 0 ? (
//           <InitialOptions 
//             onFileUpload={handleFileUpload} 
//             onGenerateImage={handleGenerateImage}
//             isGenerating={isGenerating}
//             isConverting={isConverting}
//           />
//         ) : (
//           <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8">
//             <div className="w-full lg:w-2/5">
//               <ImageList 
//                 images={images}
//                 prompts={prompts}
//                 setImages={setImages}
//                 setPrompts={setPrompts}
//                 onFocus={handleImageFocus}
//                 isLooping={isLooping}
//                 enhancedImages={enhancedImages}
//               />
              
//               <div className="mt-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
//                 <AddMoreButton onFileUpload={handleFileUpload} isConverting={isConverting} />
//                 <GenerateMoreButton onGenerateImage={handleGenerateImage} isGenerating={isGenerating} />
//               </div>
//             </div>
//             <div className="w-full lg:w-3/5">
//               <FocusedImageView 
//                 image={focusedImage} 
//                 onEnhance={handleEnhancedImage}
//                 setIsModalOpen={setIsModalOpen}
//                 setIsErrorModalOpen={setIsErrorModalOpen}
//               />
//             </div>
//           </div>
//         )}
//       </div>
//       <ExamplesSection />
//       <SubscriptionModal 
//         isOpen={isModalOpen} 
//         onClose={() => setIsModalOpen(false)} 
//       />
//       <ErrorModal
//         isOpen={isErrorModalOpen}
//         onClose={() => setIsErrorModalOpen(false)}
//         message="Servers overheating, try again later!"
//       />
//     </div>
//   );
// };

// const InitialOptions = ({ onFileUpload, onGenerateImage, isGenerating, isConverting }) => {
//   const [prompt, setPrompt] = useState('');

//   const handleGenerate = () => {
//     if (prompt.trim()) {
//       onGenerateImage(prompt);
//       setPrompt('');
//     }
//   };

//   return (
//     <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 shadow-xl border border-gray-200 border-opacity-20">
//       <input
//         type="text"
//         value={prompt}
//         onChange={(e) => setPrompt(e.target.value)}
//         placeholder="Describe the image you want to generate..."
//         className="w-full p-4 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-base sm:text-lg mb-4"
//       />
//       {isGenerating && (
//         <ProgressIndicator duration={28} isActive={isGenerating} text="Generating... Please do not refresh the page for best experience" />
//       )}
//       <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
//         <motion.button
//           whileHover={{ scale: 1.02 }}
//           whileTap={{ scale: 0.98 }}
//           onClick={handleGenerate}
//           disabled={isGenerating || !prompt.trim()}
//           className={`flex-1 bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 text-sm sm:text-base ${
//             isGenerating || !prompt.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
//           }`}
//         >
//           {isGenerating ? 'Generating...' : 'Generate'}
//         </motion.button>
//         <motion.div
//           whileHover={{ scale: 1.02 }}
//           whileTap={{ scale: 0.98 }}
//           className="flex-1"
//         >
//           <input
//             type="file"
//             multiple
//             accept="image/*"
//             className="hidden"
//             id="file-upload"
//             onChange={onFileUpload}
//             disabled={isConverting}
//           />
//           <label
//             htmlFor="file-upload"
//             className={`flex items-center justify-center w-full bg-gray-700 text-white font-semibold py-3 px-6 rounded-full hover:bg-gray-600 transition duration-300 cursor-pointer text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
//           >
//             <Upload className="mr-2" size={18} />
//             {isConverting ? 'Converting...' : 'Upload Image'}
//           </label>
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// const ImageList = ({ images, setImages, onFocus, enhancedImages }) => {
//   const handleRemoveImage = (index) => {
//     setImages(images.filter((_, i) => i !== index));
//   };

//   const handleMoveImage = (index, direction) => {
//     const newImages = [...images];
//     if (direction === 'up' && index > 0) {
//       [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
//     } else if (direction === 'down' && index < images.length - 1) {
//       [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
//     }
//     setImages(newImages);
//   };

//   return (
//     <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto pr-4">
//       {images.map((image, index) => (
//         <motion.div 
//           key={index}
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.3 }}
//           className="flex items-center bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-3 border border-gray-200 border-opacity-20"
//         >
//           <div className="flex flex-col items-center mr-2">
//             <button onClick={() => handleMoveImage(index, 'up')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300 mb-1">
//               <ChevronUp size={14} className="text-white" />
//             </button>
//             <button onClick={() => handleMoveImage(index, 'down')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300">
//               <ChevronDown size={14} className="text-white" />
//             </button>
//           </div>
//           <img 
//             src={enhancedImages[image.src] || image.src} 
//             alt={image.name} 
//             className="w-20 h-20 object-cover rounded-lg mr-3" 
//           />
//           <div className="flex-grow">
//             <p className="text-gray-200 text-sm font-medium">{image.name}</p>
//             <p className="text-xs text-gray-400">{image.type}</p>
//           </div>
//           <div className="flex space-x-2">
//             <IconButton
//               icon={<Wand2 size={16} />}
//               onClick={() => onFocus({ ...image, action: 'enhance' })}
//               tooltip="Enhance Image"
//             />
//             <IconButton
//               icon={<Download size={16} />}
//               onClick={() => downloadImage(image)}
//               tooltip="Download Image"
//             />
//             <IconButton
//               icon={<Trash2 size={16} />}
//               onClick={() => handleRemoveImage(index)}
//               tooltip="Remove Image"
//             />
//           </div>
//         </motion.div>
//       ))}
//     </div>
//   );
// };

// const IconButton = ({ icon, onClick, tooltip }) => (
//   <div className="relative group">
//     <motion.button
//       whileHover={{ scale: 1.1 }}
//       whileTap={{ scale: 0.9 }}
//       onClick={onClick}
//       className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300"
//     >
//       {React.cloneElement(icon, { className: "text-white", size: 16 })}
//     </motion.button>
//     <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
//       {tooltip}
//     </div>
//   </div>
// );

// const downloadImage = (image) => {
//   const link = document.createElement('a');
//   link.href = image.edits.length > 0 ? image.edits[image.edits.length - 1] : image.src;
  
//   let extension = 'png';
//   if (image.name.includes('.')) {
//     extension = image.name.split('.').pop();
//   } else if (image.type.includes('/')) {
//     extension = image.type.split('/').pop();
//   }
  
//   link.download = `${image.name.split('.')[0]}_edited.${extension}`;
  
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

// const enhanceImage = async (image, setIsModalOpen, setIsErrorModalOpen) => {
//   if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
//     console.error('User not authorized to upload images');
//     return;
//   }
//   try {
//     let base64Image;
//     if (image.file) {
//       base64Image = await readFileAsBase64(image.file);
//     } else {
//       const response = await fetch(image.src);
//       const blob = await response.blob();
//       base64Image = await readFileAsBase64(blob);
//     }

//     const params = {
//       FunctionName: 'ClarityUpscaler',
//       InvocationType: 'RequestResponse',
//       Payload: JSON.stringify({ file: base64Image })
//     };

//     const lambdaResponse = await lambda.invoke(params);
//     console.log("lambda response is ", lambdaResponse);
//     const result = JSON.parse(new TextDecoder().decode(lambdaResponse.Payload));
//     console.log("result response is: ", result);
//     if (result.statusCode !== 200) {
//       throw new Error(result.body);
//     }

//     const body = JSON.parse(result.body);
//     return body.output;
//   } catch (error) {
//     console.error('Error enhancing image:', error);
//     throw error;
//   }
// };

// const readFileAsBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result.split(',')[1]);
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// };

// const FocusedImageView = ({ image, onEnhance, setIsModalOpen, setIsErrorModalOpen }) => {
//   const [enhancedImage, setEnhancedImage] = useState(null);
//   const [isEnhancing, setIsEnhancing] = useState(false);
//   const [sliderPosition, setSliderPosition] = useState(50);
//   const [enhancingProgress, setEnhancingProgress] = useState(false);

//   useEffect(() => {
//     setEnhancedImage(null);
//     setIsEnhancing(false);
//     setSliderPosition(50);
//   }, [image]);

//   const handleEnhance = async () => {
//     if (image?.action === 'enhance' && !enhancedImage) {
//       setIsEnhancing(true);
//       setEnhancingProgress(true);
//       try {
//         const enhancedUrl = await enhanceImage(image, setIsModalOpen, setIsErrorModalOpen);
//         setEnhancedImage(enhancedUrl);
//         onEnhance(image, enhancedUrl);
//       } catch (error) {
//         console.error('Failed to enhance image:', error);
//       } finally {
//         setIsEnhancing(false);
//         setEnhancingProgress(false);
//       }
//     }
//   };

//   const handleSliderChange = (e) => {
//     setSliderPosition(e.target.value);
//   };

//   const handleDownload = async (isEnhanced) => {
//     const imageUrl = isEnhanced ? enhancedImage : image.src;
    
//     try {
//       const response = await fetch(imageUrl);
//       const blob = await response.blob();
      
//       const blobUrl = window.URL.createObjectURL(blob);
      
//       const link = document.createElement('a');
//       link.href = blobUrl;
      
//       let extension = 'png';
//       if (image.name.includes('.')) {
//         extension = image.name.split('.').pop();
//       } else if (image.type.includes('/')) {
//         extension = image.type.split('/').pop();
//       }
      
//       const suffix = isEnhanced ? '_enhanced' : '';
//       link.download = `${image.name.split('.')[0]}${suffix}.${extension}`;
      
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
      
//       window.URL.revokeObjectURL(blobUrl);
//     } catch (error) {
//       console.error('Error downloading image:', error);
//     }
//   };

//   return (
//     <AnimatePresence mode="wait">
//       {image && (
//         <motion.div
//           key={image.src}
//           initial={{ opacity: 0, scale: 0.9 }}
//           animate={{ opacity: 1, scale: 1 }}
//           exit={{ opacity: 0, scale: 0.9 }}
//           transition={{ duration: 0.3 }}
//           className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 h-full flex flex-col justify-center items-center border border-gray-200 border-opacity-20"
//         >
//           <h2 className="text-2xl font-bold text-gray-200 mb-4">Image Enhancement</h2>
//           <div className="relative w-full max-w-2xl aspect-square mb-6">
//             <img 
//               src={image.src} 
//               alt={image.name} 
//               className="absolute top-0 left-0 w-full h-full object-contain rounded-lg" 
//             />
//             {enhancedImage && (
//               <div 
//                 className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-lg"
//                 style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
//               >
//                 <img 
//                   src={enhancedImage} 
//                   alt={`Enhanced ${image.name}`} 
//                   className="absolute top-0 left-0 w-full h-full object-contain" 
//                 />
//               </div>
//             )}
//             {enhancedImage && (
//               <input
//                 type="range"
//                 min="0"
//                 max="100"
//                 value={sliderPosition}
//                 onChange={handleSliderChange}
//                 className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-3/4 z-10"
//               />
//             )}
//           </div>
//           {isEnhancing && (
//             <ProgressIndicator duration={120} isActive={enhancingProgress} text="Enhancing... Please do not refresh the page for best experience" />
//           )}
//           <div className="flex flex-wrap justify-center gap-4">
//             <motion.button
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//               onClick={handleEnhance}
//               disabled={isEnhancing || enhancedImage}
//               className={`bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-2 px-6 rounded-full transition duration-300 text-sm sm:text-base flex items-center ${
//                 isEnhancing || enhancedImage ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
//               }`}
//             >
//               <Sparkles className="mr-2" size={18} />
//               {isEnhancing ? 'Enhancing...' : enhancedImage ? 'Enhanced' : 'Enhance Image'}
//             </motion.button>
//             {enhancedImage && (
//               <>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   onClick={() => handleDownload(true)}
//                   className="bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold py-2 px-6 rounded-full hover:from-green-600 hover:to-teal-600 transition duration-300 text-sm sm:text-base flex items-center"
//                 >
//                   <Download className="mr-2" size={18} />
//                   Download Enhanced
//                 </motion.button>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   onClick={() => handleDownload(false)}
//                   className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-semibold py-2 px-6 rounded-full hover:from-blue-600 hover:to-indigo-600 transition duration-300 text-sm sm:text-base flex items-center"
//                 >
//                   <Download className="mr-2" size={18} />
//                   Download Original
//                 </motion.button>
//               </>
//             )}
//           </div>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// };

// const AddMoreButton = ({ onFileUpload, isConverting }) => (
//   <motion.div
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className="w-full sm:w-auto"
//   >
//     <input
//       type="file"
//       multiple
//       accept="image/*"
//       className="hidden"
//       id="add-more-files"
//       onChange={onFileUpload}
//       disabled={isConverting}
//     />
//     <label
//       htmlFor="add-more-files"
//       className={`flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 cursor-pointer shadow-lg text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
//     >
//       <Plus size={18} className="mr-2" />
//       {isConverting ? 'Converting...' : 'Add More'}
//     </label>
//   </motion.div>
// );

// const GenerateMoreButton = ({ onGenerateImage, isGenerating }) => {
//   const [prompt, setPrompt] = useState('');
//   const [buttonText, setButtonText] = useState('Generate More');

//   useEffect(() => {
//     let interval;
//     if (isGenerating) {
//       interval = setInterval(() => {
//         setButtonText((prevText) => 
//           prevText === 'Generating...' 
//             ? 'Please do not refresh the page' 
//             : 'Generating...'
//         );
//       }, 3000);
//     } else {
//       setButtonText('Generate More');
//     }
//     return () => clearInterval(interval);
//   }, [isGenerating]);

//   const handleGenerate = () => {
//     onGenerateImage(prompt);
//     setPrompt('');
//   };

//   return (
//     <motion.div
//       whileHover={{ scale: 1.05 }}
//       whileTap={{ scale: 0.95 }}
//       className="w-full sm:w-auto"
//     >
//       <input
//         type="text"
//         value={prompt}
//         onChange={(e) => setPrompt(e.target.value)}
//         placeholder="Enter your image prompt"
//         className="w-full mb-2 p-3 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-sm"
//       />
//       <button
//         onClick={handleGenerate}
//         disabled={isGenerating}
//         className={`flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 shadow-lg text-sm sm:text-base ${isGenerating ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'}`}
//       >
//         <ImageIcon size={18} className="mr-2" />
//         {buttonText}
//       </button>
//     </motion.div>
//   );
// };

// const ExamplesSection = () => {
//   const exampleImages = [
//     { src: image1, title: "Unlimited Cat", description: "Unlimited Powerful Super Saiyan Cat" },
//     { src: image2, title: "Blue Sunflower", description: "Up Close Shot of Blue Sunflower, hyper realistic, perfect textures, beautiful, happy" },
//     { src: image3, title: "Crocodile Humanoid Ninja", description: "Crocodile Humanoid in Ninja Outfit in Streets of Tokyo, raining, hyper realistic, night time, excitement" },
//   ];

//   return (
//     <div className="mt-16 mb-8">
//       <h2 className="text-4xl sm:text-5xl font-bold text-center mb-10 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
//         Discover AI-Generated Wonders
//       </h2>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//         {exampleImages.map((image, index) => (
//           <motion.div 
//             key={index} 
//             className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl overflow-hidden shadow-xl border border-gray-200 border-opacity-20"
//             whileHover={{ scale: 1.03, borderOpacity: 0.5 }}
//             transition={{ duration: 0.3 }}
//           >
//             <div className="aspect-w-16 aspect-h-9">
//               <img
//                 src={image.src}
//                 alt={image.title}
//                 className="w-full h-full object-cover"
//               />
//             </div>
//             <div className="p-6">
//               <h3 className="text-xl font-semibold text-gray-200 mb-2">{image.title}</h3>
//               <p className="text-gray-300 text-sm">{image.description}</p>
//             </div>
//           </motion.div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ImageGenerationPage;
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload, Image as ImageIcon, Wand2, Trash2, ChevronUp, ChevronDown, Plus, Download, Undo, Redo, Sparkles } from 'lucide-react';
import { Lambda } from '@aws-sdk/client-lambda';
import SubscriptionModal from './subscriptionmodel';
import ErrorModal from './ErrorModal';
import imageCompression from 'browser-image-compression';
import image1 from './cat_saiyan.png';
import image2 from './Blue_Sunflower.png';
import image3 from './Croc_Humanoid.png';

const lambda = new Lambda({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_LAMBDA_SECRET_KEY_ID
  }
});

const convertToWebP = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    fileType: 'image/webp'
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return new File([compressedFile], file.name.replace(/\.[^/.]+$/, ".webp"), { type: 'image/webp' });
  } catch (error) {
    console.error("Error converting to WebP:", error);
    return file;
  }
};

const checkUserPermission = async (action, setIsModalOpen, setIsErrorModalOpen) => {
  const userEmail = localStorage.getItem('userEmail');
  console.log("Image Gen email is, ", userEmail);
  
  try {
    const freeTrialResponse = await fetch(process.env.REACT_APP_FREE_TRIAL_API_GW, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: userEmail }),
    });

    const freeTrialData = await freeTrialResponse.json();
    const parsedFreeTrialData = JSON.parse(freeTrialData.body);

    if (parsedFreeTrialData.message === "Free trial expired") {
      console.log('Free trial exhausted, checking subscription');
      const subscriptionResponse = await fetch(process.env.REACT_APP_IMAGE_GEN_SUB_CHECK, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          action: action
        }),
      });

      const data = await subscriptionResponse.json();
      console.log("data is ", data)
      if (data.statusCode === 403){
        console.error('User not Subscribed')
        setIsModalOpen(true);
        return false;
      }
      if (data.statusCode === 404) {
        console.error('Servers overheating');
        setIsErrorModalOpen(true);
        return false;
      }
      
      return true;
    } else if(parsedFreeTrialData.message == "Credit used successfully" || parsedFreeTrialData.message == "Free trial started" ){
      return true;
    } else {
      console.error('Unexpected response from free trial API');
      return false;
    }
  } catch (error) {
    console.error('Error checking user permission:', error);
    return false;
  }
};

const ImageGenerationPage = () => {
  const [images, setImages] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [focusedImage, setFocusedImage] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLooping, setIsLooping] = useState(false);
  const [enhancedImages, setEnhancedImages] = useState({});
  const [isConverting, setIsConverting] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState(0);
  
  const handleFileUpload = async (event) => {
    if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
      console.error('User not subscribed or our Servers are overheated, check back at 6pm EST for reset');
      return;
    }

    const files = Array.from(event.target.files);
    setIsConverting(true);
    try {
      const newImages = await Promise.all(files.map(async file => {
        let webpFile = file;
        if (file.type !== 'image/webp') {
          webpFile = await convertToWebP(file);
        }
        return {
          type: 'uploaded',
          src: URL.createObjectURL(webpFile),
          name: webpFile.name,
          file: webpFile,
          edits: [],
          undoStack: [],
          redoStack: []
        };
      }));
      setImages([...images, ...newImages]);
      setPrompts([...prompts, ...Array(newImages.length).fill('')]);
    } catch (error) {
      console.error('Error converting images:', error);
    } finally {
      setIsConverting(false);
    }
  };

  const handleGenerateImage = async (prompt) => {
    if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
      return;
    }

    setIsGenerating(true);
    setGeneratingProgress(0);
    
    const intervalId = setInterval(() => {
      setGeneratingProgress(oldProgress => {
        const newProgress = oldProgress + (100 / 280); // 28 seconds * 10 (update every 100ms)
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 100);

    try {
      const params = {
        FunctionName: 'FluxProImageGeneration',
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({ prompt: prompt })
      };
  
      const response = await lambda.invoke(params);
      const result = JSON.parse(new TextDecoder().decode(response.Payload));
      
      if (result.statusCode !== 200) {
        throw new Error(result.body);
      }
  
      const body = JSON.parse(result.body);
      const imageUrl = body.output;
  
      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();
      const blobUrl = URL.createObjectURL(imageBlob);
  
      const newImage = {
        type: 'generated',
        src: blobUrl,
        name: `Generated: ${prompt}`,
        file: new File([imageBlob], `generated_${Date.now()}.png`, { type: 'image/png' }),
        edits: [],
        undoStack: [],
        redoStack: []
      };
  
      setImages(prevImages => [...prevImages, newImage]);
      setPrompts(prevPrompts => [...prevPrompts, '']);
    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      clearInterval(intervalId);
      setIsGenerating(false);
      setGeneratingProgress(0);
    }
  };

  const handleEnhancedImage = async (originalImage, enhancedUrl) => {
    if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
      return;
    }

    setEnhancedImages(prev => ({
      ...prev,
      [originalImage.src]: enhancedUrl
    }));
  };

  const handleImageFocus = (image) => {
    setFocusedImage(image);
  };

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-gradient-to-br from-gray-900 to-black text-white">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
          Generative Studio
        </h1>
        
        {images.length === 0 ? (
          <InitialOptions 
            onFileUpload={handleFileUpload} 
            onGenerateImage={handleGenerateImage}
            isGenerating={isGenerating}
            isConverting={isConverting}
            generatingProgress={generatingProgress}
          />
        ) : (
          <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8">
            <div className="w-full lg:w-2/5">
              <ImageList 
                images={images}
                prompts={prompts}
                setImages={setImages}
                setPrompts={setPrompts}
                onFocus={handleImageFocus}
                isLooping={isLooping}
                enhancedImages={enhancedImages}
              />
              
              <div className="mt-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <AddMoreButton onFileUpload={handleFileUpload} isConverting={isConverting} />
                <GenerateMoreButton 
                  onGenerateImage={handleGenerateImage} 
                  isGenerating={isGenerating}
                  generatingProgress={generatingProgress}
                />
              </div>
            </div>
            <div className="w-full lg:w-3/5">
              <FocusedImageView 
                image={focusedImage} 
                onEnhance={handleEnhancedImage}
                setIsModalOpen={setIsModalOpen}
                setIsErrorModalOpen={setIsErrorModalOpen}
              />
            </div>
          </div>
        )}
      </div>
      <ExamplesSection />
      <SubscriptionModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        message="Servers overheating, try again later!"
      />
    </div>
  );
};

const InitialOptions = ({ onFileUpload, onGenerateImage, isGenerating, isConverting, generatingProgress }) => {
  const [prompt, setPrompt] = useState('');

  const handleGenerate = () => {
    if (prompt.trim()) {
      onGenerateImage(prompt);
      setPrompt('');
    }
  };

  return (
    <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 shadow-xl border border-gray-200 border-opacity-20">
      <input
        type="text"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe the image you want to generate..."
        className="w-full p-4 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-base sm:text-lg mb-4"
      />
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleGenerate}
            disabled={isGenerating || !prompt.trim()}
            className={`relative flex-1 bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 text-sm sm:text-base overflow-hidden ${
              isGenerating || !prompt.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
            }`}
          >
            <span className="relative z-10">{isGenerating ? 'Generating...' : 'Generate'}</span>
            {isGenerating && (
              <motion.div 
                className="absolute top-0 left-0 h-full bg-blue-600"
                initial={{ width: 0 }}
                animate={{ width: `${generatingProgress}%` }}
                transition={{ duration: 0.1 }}
              />
            )}
          </motion.button>
          <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="flex-1"
          >
            <input
              type="file"
              multiple
              accept="image/*"
              className="hidden"
              id="file-upload"
              onChange={onFileUpload}
              disabled={isConverting}
            />
            <label
              htmlFor="file-upload"
              className={`flex items-center justify-center w-full bg-gray-700 text-white font-semibold py-3 px-6 rounded-full hover:bg-gray-600 transition duration-300 cursor-pointer text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <Upload className="mr-2" size={18} />
              {isConverting ? 'Converting...' : 'Upload Image'}
            </label>
          </motion.div>
        </div>
        {isGenerating && (
          <p className="text-sm text-gray-300 text-center">
            Generating... Please do not refresh the page for best experience
          </p>
        )}
      </div>
    </div>
  );
};

const ImageList = ({ images, setImages, onFocus, enhancedImages }) => {
  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleMoveImage = (index, direction) => {
    const newImages = [...images];
    if (direction === 'up' && index > 0) {
      [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
    } else if (direction === 'down' && index < images.length - 1) {
      [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
    }
    setImages(newImages);
  };

  return (
    <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto pr-4">
      {images.map((image, index) => (
        <motion.div 
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="flex items-center bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-3 border border-gray-200 border-opacity-20"
        >
          <div className="flex flex-col items-center mr-2">
            <button onClick={() => handleMoveImage(index, 'up')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300 mb-1">
              <ChevronUp size={14} className="text-white" />
            </button>
            <button onClick={() => handleMoveImage(index, 'down')} className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300">
              <ChevronDown size={14} className="text-white" />
            </button>
          </div>
          <img 
            src={enhancedImages[image.src] || image.src} 
            alt={image.name} 
            className="w-20 h-20 object-cover rounded-lg mr-3" 
          />
          <div className="flex-grow">
            <p className="text-gray-200 text-sm font-medium">{image.name}</p>
            <p className="text-xs text-gray-400">{image.type}</p>
          </div>
          <div className="flex space-x-2">
            <IconButton
              icon={<Wand2 size={16} />}
              onClick={() => onFocus({ ...image, action: 'enhance' })}
              tooltip="Enhance Image"
            />
            <IconButton
              icon={<Download size={16} />}
              onClick={() => downloadImage(image)}
              tooltip="Download Image"
            />
            <IconButton
              icon={<Trash2 size={16} />}
              onClick={() => handleRemoveImage(index)}
              tooltip="Remove Image"
            />
          </div>
        </motion.div>
      ))}
    </div>
  );
};

const IconButton = ({ icon, onClick, tooltip }) => (
  <div className="relative group">
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition duration-300"
    >
      {React.cloneElement(icon, { className: "text-white", size: 16 })}
    </motion.button>
    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
      {tooltip}
    </div>
  </div>
);

const downloadImage = (image) => {
  const link = document.createElement('a');
  link.href = image.edits.length > 0 ? image.edits[image.edits.length - 1] : image.src;
  
  let extension = 'png';
  if (image.name.includes('.')) {
    extension = image.name.split('.').pop();
  } else if (image.type.includes('/')) {
    extension = image.type.split('/').pop();
  }
  
  link.download = `${image.name.split('.')[0]}_edited.${extension}`;
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const enhanceImage = async (image, setIsModalOpen, setIsErrorModalOpen) => {
  if (!(await checkUserPermission('generate', setIsModalOpen, setIsErrorModalOpen))) {
    console.error('User not authorized to upload images');
    return;
  }
  try {
    let base64Image;
    if (image.file) {
      base64Image = await readFileAsBase64(image.file);
    } else {
      const response = await fetch(image.src);
      const blob = await response.blob();
      base64Image = await readFileAsBase64(blob);
    }

    const params = {
      FunctionName: 'ClarityUpscaler',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify({ file: base64Image })
    };

    const lambdaResponse = await lambda.invoke(params);
    console.log("lambda response is ", lambdaResponse);
    const result = JSON.parse(new TextDecoder().decode(lambdaResponse.Payload));
    console.log("result response is: ", result);
    if (result.statusCode !== 200) {
      throw new Error(result.body);
    }

    const body = JSON.parse(result.body);
    return body.output;
  } catch (error) {
    console.error('Error enhancing image:', error);
    throw error;
  }
};

const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const FocusedImageView = ({ image, onEnhance, setIsModalOpen, setIsErrorModalOpen }) => {
  const [enhancedImage, setEnhancedImage] = useState(null);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [sliderPosition, setSliderPosition] = useState(50);
  const [enhancingProgress, setEnhancingProgress] = useState(0);

  useEffect(() => {
    setEnhancedImage(null);
    setIsEnhancing(false);
    setSliderPosition(50);
    setEnhancingProgress(0);
  }, [image]);

  const handleEnhance = async () => {
    if (image?.action === 'enhance' && !enhancedImage) {
      setIsEnhancing(true);
      setEnhancingProgress(0);
      
      const intervalId = setInterval(() => {
        setEnhancingProgress(oldProgress => {
          const newProgress = oldProgress + (100 / 1200); // 120 seconds * 10 (update every 100ms)
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 100);

      try {
        const enhancedUrl = await enhanceImage(image, setIsModalOpen, setIsErrorModalOpen);
        setEnhancedImage(enhancedUrl);
        onEnhance(image, enhancedUrl);
      } catch (error) {
        console.error('Failed to enhance image:', error);
      } finally {
        clearInterval(intervalId);
        setIsEnhancing(false);
        setEnhancingProgress(0);
      }
    }
  };

  const handleSliderChange = (e) => {
    setSliderPosition(e.target.value);
  };

  const handleDownload = async (isEnhanced) => {
    const imageUrl = isEnhanced ? enhancedImage : image.src;
    
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      
      const blobUrl = window.URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = blobUrl;
      
      let extension = 'png';
      if (image.name.includes('.')) {
        extension = image.name.split('.').pop();
      } else if (image.type.includes('/')) {
        extension = image.type.split('/').pop();
      }
      
      const suffix = isEnhanced ? '_enhanced' : '';
      link.download = `${image.name.split('.')[0]}${suffix}.${extension}`;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <AnimatePresence mode="wait">
      {image && (
        <motion.div
          key={image.src}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
          className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 h-full flex flex-col justify-center items-center border border-gray-200 border-opacity-20"
        >
          <h2 className="text-2xl font-bold text-gray-200 mb-4">Image Enhancement</h2>
          <div className="relative w-full max-w-2xl aspect-square mb-6">
            <img 
              src={image.src} 
              alt={image.name} 
              className="absolute top-0 left-0 w-full h-full object-contain rounded-lg" 
            />
            {enhancedImage && (
              <div 
                className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-lg"
                style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
              >
                <img 
                  src={enhancedImage} 
                  alt={`Enhanced ${image.name}`} 
                  className="absolute top-0 left-0 w-full h-full object-contain" 
                />
              </div>
            )}
            {enhancedImage && (
              <input
                type="range"
                min="0"
                max="100"
                value={sliderPosition}
                onChange={handleSliderChange}
                className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-3/4 z-10"
              />
            )}
          </div>
          <div className="flex flex-wrap justify-center gap-4 w-full">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleEnhance}
              disabled={isEnhancing || enhancedImage}
              className={`relative bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-2 px-6 rounded-full transition duration-300 text-sm sm:text-base flex items-center justify-center w-full sm:w-auto overflow-hidden ${
                isEnhancing || enhancedImage ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'
              }`}
            >
              <span className="relative z-10">
                <Sparkles className="mr-2 inline" size={18} />
                {isEnhancing ? 'Enhancing...' : enhancedImage ? 'Enhanced' : 'Enhance Image'}
              </span>
              {isEnhancing && (
                <motion.div 
                  className="absolute top-0 left-0 h-full bg-blue-600"
                  initial={{ width: 0 }}
                  animate={{ width: `${enhancingProgress}%` }}
                  transition={{ duration: 0.1 }}
                />
              )}
            </motion.button>
            {enhancedImage && (
              <>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleDownload(true)}
                  className="bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold py-2 px-6 rounded-full hover:from-green-600 hover:to-teal-600 transition duration-300 text-sm sm:text-base flex items-center justify-center w-full sm:w-auto"
                >
                  <Download className="mr-2" size={18} />
                  Download Enhanced
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleDownload(false)}
                  className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-semibold py-2 px-6 rounded-full hover:from-blue-600 hover:to-indigo-600 transition duration-300 text-sm sm:text-base flex items-center justify-center w-full sm:w-auto"
                >
                  <Download className="mr-2" size={18} />
                  Download Original
                </motion.button>
              </>
            )}
          </div>
          {isEnhancing && (
            <p className="text-sm text-gray-300 mt-4 text-center">
              Enhancing... Please do not refresh the page for best experience
            </p>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const AddMoreButton = ({ onFileUpload, isConverting }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="w-full sm:w-auto"
  >
    <input
      type="file"
      multiple
      accept="image/*"
      className="hidden"
      id="add-more-files"
      onChange={onFileUpload}
      disabled={isConverting}
    />
    <label
      htmlFor="add-more-files"
      className={`flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full hover:from-blue-600 hover:to-teal-500 transition duration-300 cursor-pointer shadow-lg text-sm sm:text-base ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      <Plus size={18} className="mr-2" />
      {isConverting ? 'Converting...' : 'Add More'}
    </label>
  </motion.div>
);

const GenerateMoreButton = ({ onGenerateImage, isGenerating, generatingProgress }) => {
  const [prompt, setPrompt] = useState('');

  const handleGenerate = () => {
    onGenerateImage(prompt);
    setPrompt('');
  };

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-full sm:w-auto"
    >
      <input
        type="text"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Enter your image prompt"
        className="w-full mb-2 p-3 rounded-lg border border-gray-300 bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300 text-sm"
      />
      <button
        onClick={handleGenerate}
        disabled={isGenerating}
        className={`relative flex items-center justify-center w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold py-3 px-6 rounded-full transition duration-300 shadow-lg text-sm sm:text-base overflow-hidden ${isGenerating ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-teal-500'}`}
      >
        <span className="relative z-10">
          <ImageIcon size={18} className="mr-2 inline" />
          {isGenerating ? 'Generating...' : 'Generate More'}
        </span>
        {isGenerating && (
          <motion.div 
            className="absolute top-0 left-0 h-full bg-blue-600"
            initial={{ width: 0 }}
            animate={{ width: `${generatingProgress}%` }}
            transition={{ duration: 0.1 }}
          />
        )}
      </button>
      {isGenerating && (
        <p className="text-sm text-gray-300 mt-2 text-center">
          Generating... Please do not refresh the page for best experience
        </p>
      )}
    </motion.div>
  );
};

const ExamplesSection = () => {
  const exampleImages = [
    { src: image1, title: "Unlimited Cat", description: "Unlimited Powerful Super Saiyan Cat" },
    { src: image2, title: "Blue Sunflower", description: "Up Close Shot of Blue Sunflower, hyper realistic, perfect textures, beautiful, happy" },
    { src: image3, title: "Crocodile Humanoid Ninja", description: "Crocodile Humanoid in Ninja Outfit in Streets of Tokyo, raining, hyper realistic, night time, excitement" },
  ];

  return (
    <div className="mt-16 mb-8">
      <h2 className="text-4xl sm:text-5xl font-bold text-center mb-10 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">
        Discover AI-Generated Wonders
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {exampleImages.map((image, index) => (
          <motion.div 
            key={index} 
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl overflow-hidden shadow-xl border border-gray-200 border-opacity-20"
            whileHover={{ scale: 1.03, borderOpacity: 0.5 }}
            transition={{ duration: 0.3 }}
          >
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={image.src}
                alt={image.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold text-gray-200 mb-2">{image.title}</h3>
              <p className="text-gray-300 text-sm">{image.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ImageGenerationPage;
