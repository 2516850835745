// import React, { useState, useEffect } from 'react';
// import { Sparkles } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';
// import { gapi } from 'gapi-script';


// const clientId = process.env.REACT_APP_GOOGLE_AUTH_API_KEY
// if (localStorage.getItem('authenticated') === null){
//   localStorage.setItem('authenticated', false);
// }

// const SignInPage = ({ setIsAuthenticated }) => {
//   const [gapiReady, setGapiReady] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   // useEffect(() => {
//   //   const loadGapi = async () => {
//   //     const { gapi } = await import('gapi-script');
//   //     await new Promise((resolve) => gapi.load('client:auth2', resolve));
//   //     await gapi.client.init({
//   //       clientId: clientId,
//   //       scope: 'email profile',
//   //     });
//   //     setGapiReady(true);
//   //   };

//   //   loadGapi();
//   // }, []);
//   useEffect(() => {
//     const loadGapi = async () => {
//       try {
//         const { gapi } = await import('gapi-script');
//         await new Promise((resolve) => gapi.load('client:auth2', resolve));
//         await gapi.client.init({
//           clientId: clientId,
//           scope: 'email profile',
//         });
//         setGapiReady(true);
//       } catch (error) {
//         console.error('Failed to load Google API', error);
//       }
//     };

//     loadGapi();
//   }, []);
//   const handleGoogleSignIn = async () => {
//     if (!gapiReady) {
//       console.log('Google API not yet loaded');
//       return;
//     }

//     setIsLoading(true);

//     try {
//       const googleAuth = gapi.auth2.getAuthInstance();
//       const googleUser = await googleAuth.signIn();
//       const profile = googleUser.getBasicProfile();
//       const email = profile.getEmail();
      
//       localStorage.setItem('userEmail', email);

//       localStorage.setItem('authenticated', true);
//       setIsAuthenticated(true);
//       window.dispatchEvent(new Event('authChange'));

//       navigate('/create');
//     } catch (error) {
//       console.error("Error signing in with Google", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black">
//       <div className="w-full max-w-md p-8 space-y-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl shadow-xl border border-gray-200 border-opacity-20">
//         <div className="text-center">
//           <Sparkles className="text-blue-400 mx-auto mb-2" size={32} />
//           <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">Whiteberry</h1>
//           <p className="mt-2 text-xl text-gray-300">Free AI Creation and Enhancement Hub</p>
//         </div>
//         <button 
//           onClick={handleGoogleSignIn} 
//           disabled={!gapiReady || isLoading}
//           className="w-full px-4 py-3 text-white bg-gradient-to-r from-blue-500 to-teal-400 rounded-full hover:from-blue-600 hover:to-teal-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center"
//         >
//           {isLoading ? (
//             <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
//               <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//               <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//             </svg>
//           ) : (
//             <>
//               <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-5 h-5 mr-2" />
//               Sign In Securely with Google
//             </>
//           )}
//         </button>
//         <p className="text-center text-sm text-gray-400">
//           By signing in, you agree to our <a href="#" className="text-blue-400 hover:underline">Terms of Service</a> and <a href="#" className="text-blue-400 hover:underline">Privacy Policy</a>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default SignInPage;
import React, { useState, useEffect } from 'react';
import { Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';

const clientId = process.env.REACT_APP_GOOGLE_AUTH_API_KEY;
if (localStorage.getItem('authenticated') === null) {
  localStorage.setItem('authenticated', false);
}

const SignInPage = ({ setIsAuthenticated }) => {
  const [gapiReady, setGapiReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadGapi = async () => {
      try {
        const { gapi } = await import('gapi-script');
        await new Promise((resolve) => gapi.load('client:auth2', resolve));
        await gapi.client.init({
          clientId: clientId,
          scope: 'email profile',
        });
        setGapiReady(true);
      } catch (error) {
        console.error('Failed to load Google API', error);
      }
    };

    loadGapi();
  }, []);

  const handleGoogleSignIn = async () => {
    if (!gapiReady) {
      console.log('Google API not yet loaded');
      return;
    }

    setIsLoading(true);

    try {
      const googleAuth = gapi.auth2.getAuthInstance();
      const googleUser = await googleAuth.signIn();
      const profile = googleUser.getBasicProfile();
      const email = profile.getEmail();
      
      localStorage.setItem('userEmail', email);
      localStorage.setItem('authenticated', true);
      setIsAuthenticated(true);
      window.dispatchEvent(new Event('authChange'));

      navigate('/create');
    } catch (error) {
      console.error("Error signing in with Google", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black">
      <div className="w-full max-w-md p-8 space-y-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl shadow-xl border border-gray-200 border-opacity-20">
        <div className="text-center">
          <Sparkles className="text-blue-400 mx-auto mb-2" size={32} />
          <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300">Whiteberry</h1>
          <p className="mt-2 text-xl text-gray-300">AI Image Generation & Enhancement</p>
        </div>
        <button 
          onClick={handleGoogleSignIn} 
          disabled={!gapiReady || isLoading}
          className="w-full px-4 py-3 text-white bg-gradient-to-r from-blue-500 to-teal-400 rounded-full hover:from-blue-600 hover:to-teal-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center"
        >
          {isLoading ? (
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <>
              <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-5 h-5 mr-2" />
              Sign In with Google
            </>
          )}
        </button>
        <p className="text-center text-sm text-gray-400">
          By signing in, you agree to our <a href="#" className="text-blue-400 hover:underline">Terms of Service</a> and <a href="#" className="text-blue-400 hover:underline">Privacy Policy</a>
        </p>
      </div>
    </div>
  );
};

export default SignInPage;
