import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Sparkles, CreditCard, CheckCircle, XCircle } from 'lucide-react';

const ManageMembershipPage = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState('monthly');
  const email = localStorage.getItem('userEmail');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      stripe-buy-button {
        display: flex !important;
        justify-content: center !important;
      }
      stripe-buy-button > div {
        width: 100% !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    checkSubscription();
  }, []);

  const checkSubscription = async () => {
    setLoading(true);
    
    
    try {
      const checkResponse = await fetch(process.env.REACT_APP_SUBSCRIPTION_CHECK_API_GATEWAY, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email }),
      });

      const checkData = await checkResponse.json();
      console.log('Subscription Check Response:', checkData);

      let parsedCheckData = checkData;
      if (typeof checkData.body === 'string') {
        parsedCheckData = JSON.parse(checkData.body);
      }

      setIsSubscribed(parsedCheckData.isSubscribed);
    } catch (error) {
      console.error('Error checking subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnsubscribe = async () => {
    setLoading(true);
    try {
      
      const response = await fetch(process.env.REACT_APP_UNSUBSCRIBE_API_KEY, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();
      console.log('Unsubscribe Response:', data);

      if (response.ok) {
        setResult('Unsubscribed successfully');
        setIsSubscribed(false);
      } else {
        setResult(`Error: ${data.error || 'Unknown error occurred'}`);
      }
    } catch (error) {
      console.error('Unsubscribe error:', error);
      setResult('An error occurred while unsubscribing');
    } finally {
      setLoading(false);
    }
  };

  const SubscriptionToggle = () => (
    <div className="flex justify-center mb-6">
      <button
        className={`px-4 py-2 rounded-l-full ${
          subscriptionType === 'monthly'
            ? 'bg-blue-500 text-white'
            : 'bg-gray-300 text-gray-700'
        }`}
        onClick={() => setSubscriptionType('monthly')}
      >
        Monthly
      </button>
      <button
        className={`px-4 py-2 rounded-r-full ${
          subscriptionType === 'yearly'
            ? 'bg-blue-500 text-white'
            : 'bg-gray-300 text-gray-700'
        }`}
        onClick={() => setSubscriptionType('yearly')}
      >
        Yearly
      </button>
    </div>
  );

  const StripeBuyButton = ({ type }) => {
    const buttonProps = type === 'monthly'
      ? {
          // buyButtonId: "buy_btn_1PvWLyHZp9llgEUKo80GiMMM",
          // buyButtonId:"buy_btn_1PxAAqHZp9llgEUKL0dEw49N",
        // buyButtonId: "buy_btn_1Px9xEHZp9llgEUKn3YTlmJu",
        buyButtonId:"buy_btn_1PxHgqHZp9llgEUKjegABDpd",
          // publishableKey: "pk_test_51Ps7DMHZp9llgEUKl2mjFe5bImFKUtxRSRj0IGrTEw9RFrkGd60T0CfVcKUyLwsHTrB6xu7oymeEP09ueEhO1i1m000OaVDebE"
          // publishableKey: "pk_live_51Ps7DMHZp9llgEUK3dtlu1yq3s4sJBSBsKSUKNssKr5YtoSEIWlSnQBlpVrH4k0Ic6DuRfK8fwFmMmG2Qij1rO9U00g9W0m4q6"
        // publishableKey: "pk_live_51Ps7DMHZp9llgEUK3dtlu1yq3s4sJBSBsKSUKNssKr5YtoSEIWlSnQBlpVrH4k0Ic6DuRfK8fwFmMmG2Qij1rO9U00g9W0m4q6"
        publishableKey: "pk_live_51Ps7DMHZp9llgEUK3dtlu1yq3s4sJBSBsKSUKNssKr5YtoSEIWlSnQBlpVrH4k0Ic6DuRfK8fwFmMmG2Qij1rO9U00g9W0m4q6"
        }
      : {
          // buyButtonId: "buy_btn_1PwmHZHZp9llgEUK0QO2UTQZ",
        // buyButtonId: "buy_btn_1Px9xAHZp9llgEUKXgFDLv3L",
          buyButtonId: "buy_btn_1PxHjpHZp9llgEUKuPBqp9A5",
          // publishableKey: "pk_test_51Ps7DMHZp9llgEUKl2mjFe5bImFKUtxRSRj0IGrTEw9RFrkGd60T0CfVcKUyLwsHTrB6xu7oymeEP09ueEhO1i1m000OaVDebE"
        // publishableKey: "pk_live_51Ps7DMHZp9llgEUK3dtlu1yq3s4sJBSBsKSUKNssKr5YtoSEIWlSnQBlpVrH4k0Ic6DuRfK8fwFmMmG2Qij1rO9U00g9W0m4q6"
        publishableKey:"pk_live_51Ps7DMHZp9llgEUK3dtlu1yq3s4sJBSBsKSUKNssKr5YtoSEIWlSnQBlpVrH4k0Ic6DuRfK8fwFmMmG2Qij1rO9U00g9W0m4q6"
        };

    return (
      <stripe-buy-button
        buy-button-id={buttonProps.buyButtonId}
        publishable-key={buttonProps.publishableKey}
      >
      </stripe-buy-button>
    );
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gradient-to-br from-gray-900 to-black text-white">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md text-center mb-12"
      >
        <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 mb-2">Whiteberry AI</h1>
        <p className="text-xl text-gray-300">Unlock premium features with our membership plan</p>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-gray-200 border-opacity-20"
      >
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : isSubscribed ? (
          <>
            <h2 className="text-2xl font-bold text-gray-200 mb-6">Manage Your Subscription</h2>
            <div className="mb-6 p-4 bg-green-500 bg-opacity-20 rounded-lg flex items-center">
              <CheckCircle className="text-green-400 mr-3" size={24} />
              <span className="text-green-300">You are currently subscribed</span>
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleUnsubscribe}
              className="w-full bg-gradient-to-r from-red-500 to-pink-600 text-white font-semibold py-3 px-6 rounded-full hover:from-red-600 hover:to-pink-700 transition duration-300 shadow-md flex items-center justify-center"
              disabled={loading}
            >
              <XCircle className="mr-2" size={20} />
              {loading ? 'Processing...' : 'Unsubscribe'}
            </motion.button>
            {result && <p className="mt-4 text-gray-300">{result}</p>}
          </>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-gray-200 mb-6">Subscribe Now</h2>
            <div className="mb-6 p-4 bg-blue-500 bg-opacity-20 rounded-lg flex items-center">
              <Sparkles className="text-blue-400 mr-3" size={24} />
              <span className="text-blue-300">Unlock premium features with our subscription</span>
            </div>
            <SubscriptionToggle />
            <StripeBuyButton type={subscriptionType} key={subscriptionType} />
          </>
        )}
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mt-12 w-full max-w-md"
      >
        <h3 className="text-2xl font-semibold text-gray-200 mb-4">Membership Benefits</h3>
        <ul className="space-y-3">
          {[
            "Unlimited AI image generations",
            "Advanced editing tools",
            "Priority customer support",
            "Early access to new features"
          ].map((benefit, index) => (
            <motion.li 
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="flex items-center text-gray-300"
            >
              <CheckCircle className="text-green-400 mr-3" size={20} />
              {benefit}
            </motion.li>
          ))}
        </ul>
      </motion.div>

      <p className="mt-12 text-sm text-gray-400">
        Questions? Contact us at <a href="mailto:kendiffuse2@gmail.com" className="text-blue-400 hover:text-blue-300 hover:underline">kendiffuse2@gmail.com</a>
      </p>
    </div>
  );
};

export default ManageMembershipPage;
